import { Spin, Pagination } from "antd";
import { Col, Row } from 'reactstrap'
import React, { useEffect, useState } from "react";
// import Logo from '../../images/rfc.png'
// import { Link } from 'react-router-dom';
import axios from 'axios'
import PoolWidget from './PoolWidget'
import { ChevronLeft, ChevronRight } from 'react-feather'

function AllAirdrops() {

    const [isPoolDataLoading, setIsPoolDataLoading] = useState(false)
    const [poolList, setPoolList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalRecords, setTotalRecords] = useState(1)
    const [pagesize, setPageSize] = useState(10)

    const fetchAllPools = async () => {
        setIsPoolDataLoading(true)
        try {
            let config = {
                method: 'get',
                url: `${process.env.REACT_APP_BACKEND_API_BASE_URL}/airdrop?limit=${pagesize}&page=${currentPage}`,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': `${process.env.REACT_APP_BACKEND_API_KEY}`
                }
            };
            const response = await axios(config)
            if (response && response.status === 200) {
                const poolDetailsResponse = response.data.documents
                setTotalRecords(poolDetailsResponse.length)
                setPoolList(poolDetailsResponse)
              } else {
                setPoolList([])
              }
              setIsPoolDataLoading(false)
        } catch (error) {
            setIsPoolDataLoading(false)
        }
    }

    useEffect(() => {
        fetchAllPools()
    }, [])

    useEffect(() => {
        fetchAllPools()
    }, [currentPage, pagesize])

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
          return <a><ChevronLeft className='pagination-arrows' /></a>;
        }
        if (type === 'next') {
          return <a><ChevronRight className='pagination-arrows' /></a>;
        }
        return originalElement;
    };

    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize)
    }

    const onChange = (page) => {
        setCurrentPage(page)
    };
    
    return (
        <div>
            <Row>
                {
                    isPoolDataLoading ? (
                        <Col lg="12" md="12" sm="12">
                            <div className='d-flex justify-content-center'>
                                <Spin size='medium' />
                            </div>
                        </Col>
                    ) : (
                        <>
                            {
                                poolList.map((item, index) => (
                                    <Col xxl="4" xl="4" lg="6" md="6" sm="12"  key={index}>
                                        <PoolWidget
                                            data={item}
                                            key={index} />
                                    </Col>
                                ))
                            }
                            <div className='d-flex justify-content-center mt-4'>
                                <Pagination
                                    current={currentPage}
                                    pageSize={pagesize}
                                    defaultCurrent={1}
                                    pageSizeOptions={["9", "15", "30", "60", "90"]}
                                    onChange={onChange}
                                    showSizeChanger
                                    onShowSizeChange={onShowSizeChange}
                                    total={totalRecords} 
                                    itemRender={itemRender}
                                    />
                            </div>
                         </>
                    )
                }
            </Row>
        </div>
    )
}

export default AllAirdrops