import './App.css';
import { BrowserRouter as Router } from 'react-router-dom'
import { DataProvider } from './GlobalState';
import LayoutView from './LayoutView';
import { theme, ConfigProvider } from 'antd';

function App() {

  const { darkAlgorithm } = theme;

  return (
    <DataProvider>
      <Router>
        <div className="App">
          <ConfigProvider
            theme={{
              algorithm: [darkAlgorithm],
              token: {
                colorPrimary: '#4b25dd',
                // fontFamily: "Noto Sans sans-serif"
              },
            }}
          >
            <LayoutView />
          </ConfigProvider>
        </div>
      </Router>
    </DataProvider>
  );
}

export default App;
