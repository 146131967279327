import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tokenAddress: '',
    symbolOfToken: '',
    airdropTitle: '',
    logoUrl: '',
    website: '',
    facebookUrl: '',
    githubUrl: '',
    instagramUrl: '',
    redditUrl: '',
    description: '',
    discordUrl: '',
    telegramUrl: '',
    twitterUrl: ''
};

export const createAirDropSlice = createSlice({
    name: "createNewAirdrop",
    initialState,
    reducers: {
        setTokenAddress: (state, action) => {
            state.tokenAddress = action.payload
        },
        setSymbolOfToken: (state, action) => {
            state.symbolOfToken = action.payload
        },
        setAirdropTitle: (state, action) => {
            state.airdropTitle = action.payload
        },
        setLogoUrl: (state, action) => {
            state.logoUrl = action.payload
        },
        setWebsite: (state, action) => {
            state.website = action.payload
        },
        setFacebookUrl: (state, action) => {
            state.facebookUrl = action.payload
        },
        setGithubUrl: (state, action) => {
            state.githubUrl = action.payload
        },
        setInstagramUrl: (state, action) => {
            state.instagramUrl = action.payload
        },
        setRedditUrl: (state, action) => {
            state.redditUrl = action.payload
        },
        setDescription: (state, action) => {
            state.description = action.payload
        },
        setDiscordUrl: (state, action) => {
            state.discordUrl = action.payload
        },
        setTelegramUrl: (state, action) => {
            state.telegramUrl = action.payload
        },
        setTwitterUrl: (state, action) => {
            state.twitterUrl = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { 
    setTokenAddress,
    setSymbolOfToken,
    setAirdropTitle,
    setLogoUrl,
    setWebsite,
    setFacebookUrl,
    setGithubUrl,
    setInstagramUrl,
    setRedditUrl,
    setDescription,
    setDiscordUrl,
    setTelegramUrl,
    setTwitterUrl
} = createAirDropSlice.actions;

export default createAirDropSlice.reducer;