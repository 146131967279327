import React, {useEffect, useState} from 'react'
// import rfc from '../../images/rfc.png'
// import BankRoll from '../../Components/Bankroll/BankRoll'
import { Steps } from 'antd';
import PrepareStep from '../../Components/HomeSteps/PrepareStep'
import ApproveStep from '../../Components/HomeSteps/ApproveStep'
import MultisendResult from '../../Components/HomeSteps/MultisendResult'
import './Home.css'
import { useSelector, useDispatch } from 'react-redux'
// import { FormOutlined, FileDoneOutlined, CheckCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { setStepNumber } from '../../Redux/HomeSteps'

function Home() {

  const [current, setCurrent] = useState(0);
  const [monitorWidth, setMonitorWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false)
  // const [csvSecondColumnArray, setCsvSecondColumnArray] = useState([])
  const descriptionPrepare = 'Prepare your data';
  const descriptionApprove = 'Approve & Airdrop';
  const descriptionMultisendResult = 'Successfully done';

  const steps = [
    {
      title: 'Prepare',
      content: <PrepareStep />,
      description: descriptionPrepare,
      // icon: <FormOutlined />
    },
    {
      title: 'Approve & Airdrop',
      content: <ApproveStep />,
      description: descriptionApprove,
      // icon: <CheckCircleOutlined />
    },
    {
      title: 'Bulksend Result',
      content: <MultisendResult />,
      description: descriptionMultisendResult,
      // icon: <FileDoneOutlined />
    },
  ];

  const stepsMobile = [
    {
      title: 'Prepare',
      content: <PrepareStep />,
      description: '',
      // icon: <FormOutlined />
    },
    {
      title: 'Approve',
      content: <ApproveStep />,
      description: '',
      // icon: <CheckCircleOutlined />
    },
    {
      title: 'Result',
      content: <MultisendResult />,
      description: '',
      // icon: <FileDoneOutlined />
    },
  ];

  const dispatch = useDispatch()
    
  const { stepNumber } = useSelector((state) => state.homeSteps)

  useEffect(() => {
    setCurrent(stepNumber)
  }, [stepNumber])

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    description: item.description,
    icon: item.icon
  }));

  const itemsMobile = stepsMobile.map((item) => ({
    key: item.title,
    title: item.title,
    description: item.description,
    icon: item.icon
  }));

  const onChange = (value) => {
    
    if (value === 2) {
      dispatch(setStepNumber(2))
    } else {
      setCurrent(value);
    }
  };
  // const prev = () => {
  //   setCurrent(current - 1);
  //   dispatch(setStepNumber(current - 1))
  // };

  useEffect(() => {
    function handleResize() {
      setMonitorWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (monitorWidth < 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
    // console.log('isMobile', isMobile);
  }, [monitorWidth])

  return (
    <div className='text-light container mt-5'>
      <div className='d-flex justify-content-center align-items-center'>
        <div className='col-lg-8 col-md-8 col-sm-12'>
          <Steps 
            current={current} 
            items={isMobile ? itemsMobile : items}
            // type={isMobile ? 'inline' : 'navigation'}
            type='default'
            // onChange={onChange}
            responsive={false}
          />
          {
            isMobile ? (
              <div className="steps-content">
                {stepsMobile[current].content}
              </div>
            ) : (
              <div className="steps-content">
                {steps[current].content}
              </div>
            )
          }
          
        </div>
      </div>
    </div>
  )
}

export default Home