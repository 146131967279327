import React from 'react'
import { Card, Spin } from 'antd';
import NumberFormat from 'react-number-format'
// import * as NumberFormat from "react-number-format"

function ApproveDetailCard(props) {

    const { 
        topic, 
        value, 
        // symbol, 
        loading 
    } = props
    
    // const formatValue = (num) => {
        
    //     if (num) {
    //         const roundedNum = Math.round(num * 10000) / 10000; // round to 4 decimal places
    //         const fixedNum = roundedNum.toFixed(4);
    //         // const numToString = num.toString(); // convert number to string
            
    //         // const fixedNum = Number(numToString).toFixed(4);
    //         return fixedNum
    //     } else {
    //         return 0
    //     }
    // }

    return (
        <>
            <div className='mx-2'>
                <Card className='home-inner-card'>
                    {
                        loading ? 
                            <Spin size="small" /> 
                                : 
                            <>
                                
                                <div className='inner-card-value'>
                                    {/* {formatValue(value)}
                                    {value} */}
                                    <NumberFormat
                                        value={value}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={5}
                                    />
                                </div>
                                <div className='inner-card-topic'>
                                    {topic}
                                </div>
                            </>
                    }
                </Card>
            </div>
        </>
    )
}

export default ApproveDetailCard;