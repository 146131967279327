import React, { useEffect, useState } from "react";
import { Card, Image, Tag, Button, Spin, Tooltip } from 'antd'
import RfcLogo from '../../images/rfc.png'
import { Link } from 'react-router-dom';
import { getEllipsisTxt, getSliceLongTxt } from '../../Helpers/Formatters'
import { getPoolDetailsAndToken } from "../../Blockchain/web3.service";
import { DateTime } from 'luxon'
import Countdown from 'react-countdown'
// import { getEllipsisTopicTxt } from '../../Helpers/Formatters'

function PoolWidget(props) {
    
    const { data: poolData } = props
    const currentUTC = parseInt(DateTime.now().toSeconds())

    const [isTokenDetailsData, setIsTokenDetailsData] = useState(false)
    const [poolTokenData, setPoolTokenData] = useState([])
    // eslint-disable-next-line
    const [poolStartIn, setPoolStartIn] = useState('')
    const [poolStatus, setPoolStatus] = useState('Pending')
    const [poolStatusColor, setPoolStatusColor] = useState('error')

    const getPoolImage = () => {
        if (poolData && poolData.logo) {
            return poolData.logo
        } else {
            return RfcLogo
        }
    }

    const fetchPoolDetailsAndToken = async () => {
        setIsTokenDetailsData(true)
        try {
            const tokenDetailsResponse = await getPoolDetailsAndToken(poolData?.poolAddress)
            setPoolTokenData(tokenDetailsResponse)
            setIsTokenDetailsData(false)
        } catch (error) {
            setIsTokenDetailsData(false)
        }
    }

    useEffect(() => {
        if (poolData) {
            fetchPoolDetailsAndToken()
        }
        // eslint-disable-next-line
    }, [poolData])

    useEffect(() => {
        if (poolTokenData?.startTime) {
            if (parseInt(poolTokenData?.startTime) === 0) {
                setPoolStartIn(poolTokenData?.startTime)
                setPoolStatus('Pending')
                setPoolStatusColor('error')
            } else if (parseInt(poolTokenData?.startTime) > currentUTC) {

                const poolStartTime = parseInt(poolTokenData?.startTime)
                const startTimeTimestamp = DateTime.fromSeconds(poolStartTime)
                const startTimeCountdown = (
                    <div>
                        <Countdown
                            date={startTimeTimestamp ? startTimeTimestamp.toString() : DateTime.now().toString()}
                            renderer={props => (
                                <div>
                                {props.formatted.days}:
                                {props.formatted.hours}:
                                {props.formatted.minutes}:
                                {props.formatted.seconds}
                                </div>
                            )}
                            zeroPadTime={2}
                        />
                    </div>
                )
                setPoolStartIn(startTimeCountdown)
                setPoolStatus('Ongoing')
                setPoolStatusColor('warning')
            } else {
                setPoolStartIn(<Tag color="success">Live</Tag>)
                setPoolStatus('Live')
                setPoolStatusColor('success')
            }
        }
        // eslint-disable-next-line
    }, [poolTokenData])

    return (
        <div>
            <div className='col-lg-12 mt-4'>
                <div className='card-border-wrap'>
                    <Card className='rfc-button-card'>
                        <div className="d-flex justify-content-center">
                            <Image
                                fallback={RfcLogo}
                                preview={false}
                                src={getPoolImage()}
                                alt={poolData ? poolData.title : ''}
                                className="card-logo" />
                        </div>
                        <div className="d-flex justify-content-end">
                            {
                                isTokenDetailsData ? (
                                    <div className="d-flex justify-content-center">
                                        <Spin size="small" />
                                    </div>
                                ) : (
                                    <Tag color={poolStatusColor}>{poolStatus}</Tag>
                                )
                            }
                            <div>
                                
                            </div>
                        </div>
                        <div className="mt-3">
                            {/* <span className="airdrop-list-topic">{poolData ? poolData.title : ''}</span>  */}
                            <Tooltip placement="top" title={poolData ? poolData.title : ''} color='#28296e'>
                                <h4>{getSliceLongTxt(poolData ? poolData.title : '')}</h4> 
                            </Tooltip>
                            
                        </div>

                        <div className="d-flex justify-content-between mt-4">
                            <span>Pool Address</span>
                            <a href={`https://testnet.bscscan.com/address/${poolData ? poolData?.poolAddress : ''}`} target={'_blank'} rel="noreferrer">
                                <span>{getEllipsisTxt(poolData ? poolData.poolAddress : '', 6)}</span>
                            </a>
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                            <span>Pool Name</span>
                            <span className="text-muted">{(poolData ? poolData.title : '')}</span>
                        </div>
                        <div className="mt-3">
                            <div className=''>
                                {
                                    isTokenDetailsData ? (
                                        <div className="d-flex justify-content-center">
                                            <Spin size="small" />
                                        </div>
                                    ) : (
                                        <>
                                        <div className="d-flex justify-content-between">
                                            <span>Token</span>
                                            <span className="text-muted">{poolTokenData?.tokenName}</span>
                                        </div>
                                        <div className="d-flex justify-content-between mt-3">
                                            <span>Total Token</span>
                                            <span className="text-muted">{poolTokenData?.totalRequiredForAirdrop}</span>
                                        </div>
                                        <div className="d-flex justify-content-between mt-3">
                                            <span>Participants</span>
                                            <span className="text-muted">{poolTokenData?.numberOfUsers}</span>
                                        </div>
                                        {/* <div className="d-flex justify-content-between mt-2">
                                            <span className="text-muted">Starts In:</span>
                                            <span>{poolStartIn}</span>
                                        </div> */}
                                        </>
                                    )
                                }
                            </div>
                            <div className="d-flex justify-content-center mt-5">
                                <Link to={`/airdrop-details/${poolData.poolAddress}`}>
                                    <Button type="primary">View Airdrop</Button>
                                </Link>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default PoolWidget