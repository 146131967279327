import React from "react";
import { Tabs } from "antd";
// import { Row, Col } from 'reactstrap'
import AllAirdrops from '../../Components/Airdrop/AllAirdrops'
import UserAirdrops from '../../Components/Airdrop/UserAirdrops'

function AirdropList() {
    return (
        <div className='text-light container mt-5'>
            <div className='d-flex justify-content-center align-items-center'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                    <span className="page-topic">Airdrop</span>
                    <div className='col-lg-12 mt-4'>
                        {/* <div className='card-border-wrap'>
                            <Card className='rfc-button-card'>
                                <div>
                                    <Row>
                                        <Col lg="6" md="6" sm="12">
                                            <div>
                                                <div className="d-flex justify-content-center">
                                                    <span>AIRDROP LAUNCHED</span>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <span className="airdrop-list-header-number">8</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg="6" md="6" sm="12">
                                            <div>
                                                <div className="d-flex justify-content-center">
                                                    <span>PARTICIPANTS IN ALL-TIME</span>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <span className="airdrop-list-header-number">249</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                </div>
                            </Card>
                        </div> */}
                        <div className="mt-5">
                            <Tabs size='small' centered defaultActiveKey="1">
                                <Tabs.TabPane tab='All Airdrops' key="1">
                                    <AllAirdrops />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab='My Airdrops' key="2">
                                    <UserAirdrops />
                                </Tabs.TabPane>
                                {/* <Tabs.TabPane tab='Created By You' key="3">
                                    Created By You
                                </Tabs.TabPane> */}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AirdropList