import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import './MenuComponent.css'
import {
  RocketFilled,
  WalletOutlined,
  ThunderboltOutlined
} from '@ant-design/icons'
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

function MenuComponent(props) {

  const navigate = useNavigate()
  const location = useLocation()
  const { setShowMobileMenu } = props
  const { margin } = useSelector((state) => state.margin);
  const [hideChildren, setHideChildren] = useState(false)

  const [openKeys, setOpenKeys] = useState([location.pathname]);

  useEffect(() => {
    if (!margin) {
      setHideChildren(true)

      setTimeout(function () {
        setHideChildren(false)
      }, 500)
    } else {
      setHideChildren(false)
    }
  }, [margin])

  const getItem = (label, key, icon, children, theme) => {
    return {
      key,
      icon,
      children,
      label,
      theme,
    };
  }

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const rootSubmenuKeys = ['/', '/airdrops', '/airdrop/create'];
  // menu items
  const items = [
    getItem('Bulksend', '/', <RocketFilled />),
    // getItem('Create Airdrop', '/airdrop/create', <FileAddOutlined />),
    // getItem('Airdrop List', '/airdrops', <BarsOutlined />),
    getItem('Token claim', '/airdrop', <WalletOutlined />, [
      getItem('Create Airdrop Pool', '/airdrop/create-pool'),
      getItem('Airdrop List', '/airdrops')
    ]),
    getItem('Create Airdrop', '/create-airdrop', <ThunderboltOutlined />)
  ];

  // onclick event on a menu item, navigates ti relevant route
  const onClick = (e) => {
    if (setShowMobileMenu) {
      navigate(e.key);
      setShowMobileMenu(false)
    } else {
      navigate(e.key);
    }
  };

  return (
    <>
      {/* menu section */}
      <Menu
        openKeys={hideChildren ? null : openKeys}
        onOpenChange={onOpenChange}
        selectedKeys={location.pathname}
        theme="dark"
        mode='inline'
        onClick={onClick}
        defaultSelectedKeys={['/']}
        items={items}
      />
    </>
  )
}

export default MenuComponent
