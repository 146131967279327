import { Button, Card, Modal, Input, Alert, notification, Radio, DatePicker, Spin } from "antd";
import React, { useEffect, useState } from "react";
// import { highlight, languages } from 'prismjs/components/prism-core';
import WAValidator from 'wallet-address-validator'
import { useParams } from "react-router-dom";
import { useWeb3React } from '@web3-react/core'
import { 
    allocationTokens, 
    getTotalRequiredBalanceForAirdrop, 
    approveStartTime ,
    changeStart,
    setVestings
    // tokenApproval
} from '../../../Blockchain/web3.service'
import { utils } from "ethers";
import moment from 'moment'
import { DateTime } from 'luxon'
import { useSelector, useDispatch } from "react-redux";
import { setIsOwnerZoneChangesDone } from '../../../Redux/AirdropDetails';
const code = ``;

function OwnerZone(props) {
    
    const dispatch = useDispatch()

    const currentUTC = parseInt(DateTime.now().toSeconds())
    const { tokenAddress, tokenDecimals } = useSelector((state) => state.selectedAirdropDetail)

    // const { tokenDecimals, tokenAddress } = props
    const { TextArea } = Input;
    const params = useParams();
    const { library } = useWeb3React()

    const [open, setOpen] = useState(false);
    const [textAreaValue, setTextAreaValues] = useState('');
    const [enterDataErrorList, setEnterDataErrorList] = useState(code);
    const [duplicatesList, setDuplicatesList] = useState([])
    const [walletAddressArray, setWalletAddressArray] = useState([]);
    const [amountArray, setAmountArray] = useState([]);
    const [isAllocationLoading, setIsAllocationLoading] = useState(false);
    const [canAllocation, setCanAllocation] = useState(false);
    const [airdropStartTime, setAirdropStartTime] = useState(currentUTC);

    const [isMinimumBalanceLoading, setIsMinimumBalanceLoading] = useState(false);
    const [minimumBalanceRequired, setMinimumBalanceRequired] = useState(0);

    const [startModalOpen, setStartModalOpen] = useState(false);
    const [vestingModalOpen, setVestingModalOpen] = useState(false);
    const [isSetVestingLoading, setIsSetVestingLoading] = useState(false);
    const [isStartNow, setIsStartNow] = useState(true);
    const [radioSelectedValue, setRadioSelectedValue] = useState(1);
    const [isChangeStartTimeLoading, setIsChangeStartTimeLoading] = useState(false);
    const [approveChangeStartTimeLoading, setApproveChangeStartTimeLoading] = useState(false);
    const [isChangeStartTimeApproved, setIsChangeStartTimeApproved] = useState(false);

    const [initialRelease, setInitialRelease] = useState(0)
    const [releasePercentage, setReleasePercentage] = useState(0)
    const [releaseTime, setReleaseTime] = useState(0)
    const [canSetVesting, setCanSetVesting] = useState(false);

    const changeStartTime = (e) => {
        const checkValue = e.target.value

        if (checkValue === 1) {
            setIsStartNow(true)
            setAirdropStartTime(currentUTC)
            setRadioSelectedValue(1)
        } else {
            setIsStartNow(false)
            setRadioSelectedValue(2)
        }
      };

    const onChange = (e) => {
        setTextAreaValues(e.target.value)
      };

    useEffect(() => {
        let textAreaValues = []
        let walletAddressArrayList = []
        let amountsArrayList = []
        const addressAndValues = []
        let totalTokens = 0
        let errorList = []
        let duplicates = []
        let frequencyMap = {}
        
        if (textAreaValue) {
            
            textAreaValues = textAreaValue.split('\n');

            textAreaValues.forEach((item, index) => {
                if (index < 101 && tokenDecimals) {
                    let separatedValues = item.split(',')
                    
                    const valid = WAValidator.validate(separatedValues[0], 'ETH')
                    if (valid) {
                        walletAddressArrayList.push(separatedValues[0])
                    } else {
                        const message = `Line ${index + 1}: Given address ${separatedValues[0]} is not a valid Ethereum address.`
                        errorList.push(message)
                    }
                    if (separatedValues[1] !== '') {
                        if (isNaN(separatedValues[1]) || (separatedValues[1] === '' || (separatedValues[1] === 0))) {
                            const message = `Line ${index + 1}: Given token value ${separatedValues[1]} is not a valid amount.`
                            errorList.push(message)
                        } else {
                            const tokenAmount = parseFloat(separatedValues[1])
                            const tokenAmountFormatted = utils.parseUnits(separatedValues[1], tokenDecimals)
                            // tokenAmount * (10 ** tokenDecimals)
                            // console.log('tokenAmountFormatted', tokenAmountFormatted.toString());
                            amountsArrayList.push(tokenAmountFormatted)
    
                            totalTokens = totalTokens + tokenAmount
                        }
                    }
                    
                    addressAndValues.push({
                        contractAddress: separatedValues[0],
                        value: separatedValues[1]
                    })
                } else {
                    setTextAreaValues(textAreaValues.slice(0, 100).join("\n"))
                    
                    notification['error']({
                        message: 'Allowed 100 addresses only',
                        description:
                            '100 addresses only for one time',
                    })
                }
            })

            for (let i = 0; i < walletAddressArrayList.length; i++) {
                if (frequencyMap[walletAddressArrayList[i]] === undefined) { 
                    frequencyMap[walletAddressArrayList[i]] = 1;
                  } else {
                    if (duplicates.indexOf(walletAddressArrayList[i]) === -1) {
                      duplicates.push(walletAddressArrayList[i]);
                    }
                    frequencyMap[walletAddressArrayList[i]] += 1;
                  }
            }
            setDuplicatesList(duplicates)
            setWalletAddressArray(walletAddressArrayList)
            setAmountArray(amountsArrayList)
            setEnterDataErrorList(errorList)
        } else {
            setEnterDataErrorList([])
            setWalletAddressArray([])
            setAmountArray([])
            setDuplicatesList([])
            
        }
        // eslint-disable-next-line
    }, [textAreaValue])

    useEffect(() => {
        if (walletAddressArray.length > 0 && amountArray.length > 0 && enterDataErrorList.length === 0 && duplicatesList.length === 0) {
            setCanAllocation(true)
        } else {
            setCanAllocation(false)
        }
    }, [walletAddressArray, amountArray, enterDataErrorList, duplicatesList])

    const showModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const showStartModal = () => {
        setStartModalOpen(true);
    }
    const handleStartModalCancel = () => {
        setStartModalOpen(false);
    };

    const showVestingModal = () => {
        setVestingModalOpen(true)
    }
    const handleVestingModalCancel = () => {
        setVestingModalOpen(false);
    };

    const handleAllocation = async () => {
        setIsAllocationLoading(true)
        try {
            if (walletAddressArray.length > 0 && amountArray.length > 0) {
                const result = await allocationTokens(walletAddressArray, amountArray, params?.airdropAddress, library.getSigner())
                console.log('result', result)
                setIsAllocationLoading(false)
                setOpen(false);
                notification['success']({
                    message: 'Success',
                    description:
                        'Tokens allocated successfully',
                })
                fetchMinimumTokenBalanceToAirdrop()
                dispatch(setIsOwnerZoneChangesDone(true))
            } else {
                
            }
        } catch (error) {
            console.log(error);
            setIsAllocationLoading(false)
        }
    }

    const approveChangeStartTime = async () => {
        setApproveChangeStartTimeLoading(true)
        try {
            const result = await approveStartTime(params?.airdropAddress, tokenAddress, minimumBalanceRequired, library.getSigner())
            if (result) {
                setIsChangeStartTimeApproved(true)
                notification['success']({
                    message: 'Success',
                    description:
                        'Approve success for change airdrop time',
                })
            } else {
                setIsChangeStartTimeApproved(false)
            }
            setApproveChangeStartTimeLoading(false)
        } catch (error) {
            setApproveChangeStartTimeLoading(false)
            notification['error']({
                message: 'Error!',
                description:
                    'Something wrong with Approve for change airdrop time',
            })
        }
    }

    const handleChangeStartTime = async () => {
        setIsChangeStartTimeLoading(true)
        try {
            const result = await changeStart(params?.airdropAddress, airdropStartTime, library.getSigner())
            if (result) {
                setIsChangeStartTimeLoading(false)
                notification['success']({
                    message: 'Success',
                    description:
                        'Change airdrop time',
                })
                setStartModalOpen(false);
                dispatch(setIsOwnerZoneChangesDone(true))
            }
        } catch (error) {
            setIsChangeStartTimeLoading(false)
            notification['error']({
                message: 'Error!',
                description:
                    'Something wrong with change airdrop time',
            })
        }
    }

    const handleAirdropStartDate = (value, dateString) => {
        const selectedTime = parseFloat(moment(dateString).valueOf())
        setAirdropStartTime(selectedTime / 1000)
    }

    const handleVesting = async () => {
        setIsSetVestingLoading(true)
        try {
            let releaseTimeFormat = releaseTime * 60

            const result = await setVestings(params?.airdropAddress, initialRelease, releasePercentage, releaseTimeFormat, library.getSigner())
            if (result) {
                setIsSetVestingLoading(false)
                notification['success']({
                    message: 'Success',
                    description:
                        'Set Vesting Successfully',
                })
                setVestingModalOpen(false);
                dispatch(setIsOwnerZoneChangesDone(true))
            }
        } catch (error) {
            console.log('error', error);
            setIsSetVestingLoading(false)
            notification['error']({
                message: 'Error!',
                description:
                    'Something wrong with set vesting',
            })
        }
    }
    
    const fetchMinimumTokenBalanceToAirdrop = async () => {
        setIsMinimumBalanceLoading(true)
        try {
            const result = await getTotalRequiredBalanceForAirdrop(params?.airdropAddress, tokenDecimals)
            if (result) {
                setMinimumBalanceRequired(result)
                setIsMinimumBalanceLoading(false)
            }
        } catch (error) {
            setMinimumBalanceRequired(0)
            setIsMinimumBalanceLoading(false)
        }
    }

    useEffect(() => {
        if (tokenDecimals) {
            fetchMinimumTokenBalanceToAirdrop()
        }
        // eslint-disable-next-line
    }, [tokenDecimals])

    useEffect(() => {
        if (initialRelease > 0 && releasePercentage > 0 && releaseTime > 0) {
            setCanSetVesting(true)
        } else {
            setCanSetVesting(false)
        }
    }, [initialRelease, releasePercentage, releaseTime])

    return (
        <div>
            <div className='card-border-wrap'>
                <Card className='rfc-button-card'>
                    <div className="d-flex justify-content-start">
                        <h5>Owner Zone</h5>
                    </div>
                    <div className="bottom-divider"></div>
                    <div className="d-flex justify-content-start mt-3">
                        Allocation Actions
                    </div>
                    <div className="mt-3">
                        <Button block onClick={showModal}>Set Allocation</Button>
                    </div>
                    <div className="mt-3">
                        <Button block onClick={showStartModal}>Start Airdrop</Button>
                    </div>
                    <div className="mt-3">
                        <Button block  onClick={showVestingModal}>Enable Vesting</Button>
                    </div>
                </Card>
            </div>
            <Modal
                open={open}
                title="Set Allocations"
                onCancel={handleCancel}
                footer={[
                    <div className="d-flex justify-content-center">
                        <Button onClick={handleAllocation} loading={isAllocationLoading} disabled={!canAllocation}>Add Allocations</Button>
                    </div>
                ]}
                width={700}
            >
                <span>Users Allocation</span>
                <TextArea
                    style={{
                        height: 250,
                        resize: 'none',
                    }}
                    onChange={onChange}
                    placeholder="0x5f4C125A2dbC35DcdabF703E4c71443D19d6Bfb9, 100"
                    value={textAreaValue}
                />
                {
                    enterDataErrorList.length > 0 ? (
                        enterDataErrorList.map((data, index) => (
                            <div className='mt-1'>
                                <Alert message={data} type="error" key={index} />
                            </div>
                        ))
                    ) : (
                        <></>
                    )
                }

                {
                    duplicatesList.length > 0 ? (
                        <div className='mt-3'>
                            <Alert message={`Found ${duplicatesList.length} duplicate addresses`} type="warning" showIcon />
                            {duplicatesList.map((data, index) => (
                                <div className='mt-1'>
                                        <Alert message={data} type="warning" key={index} />
                                </div>
                            ))}
                        </div>
                        
                    ) : (
                        <></>
                    )
                }

            </Modal>

            <Modal
                open={startModalOpen}
                title="Setting time to start"
                onCancel={handleStartModalCancel}
                footer={[
                    <div className="d-flex justify-content-center">
                        {
                            isChangeStartTimeApproved ? (
                                <Button onClick={handleChangeStartTime} loading={isChangeStartTimeLoading} disabled={isChangeStartTimeLoading}>Change Start Time</Button>
                            ) : (
                                <Button onClick={approveChangeStartTime} loading={approveChangeStartTimeLoading} disabled={approveChangeStartTimeLoading || !(minimumBalanceRequired >0)}>Approve</Button>
                            )
                        }
                        
                    </div>
                ]}
                width={400}
            >
                <div className="bottom-divider"></div>
                {
                    (minimumBalanceRequired > 0) ? (
                        <></>
                    ) : (
                        <div className="mt-3">
                            <Alert message='Before set start time, you need to allocate tokens' type="warning" />
                        </div>
                    )
                }
                <div className="mt-2">
                    <span>Set time to start airdrop</span>
                </div>
                
                <Radio.Group onChange={changeStartTime} className='mt-2' value={radioSelectedValue}>
                    <Radio value={1}>Start Now</Radio>
                    <Radio value={2}>Start with specific time</Radio>
                </Radio.Group>
                <div>
                    {
                        !isStartNow ? (
                            <div className="mt-3">
                                <span>Start time (UTC)</span>
                                <DatePicker
                                    className="col-12 rounded-input"
                                    format='YYYY-MM-DD HH:mm:ss'
                                    onChange={handleAirdropStartDate}
                                    disabledDate={d => d.isBefore(moment().utc().toString())}
                                    showTime
                                    placeholder='Select date'
                                    size="large"
                                />
                                <span className="need-tokens">Set the time that you want to start airdrop</span>
                            </div>
                        ) : (
                            <></>
                        )
                    }
                    
                </div>
                <div className="mt-3">
                    {
                        isMinimumBalanceLoading ? (
                            <div className="d-flex justify-content-center">
                                <Spin size="small" />
                            </div>
                        ) : (
                            <span className="need-tokens">You need atleast { minimumBalanceRequired} tokens to airdrop</span>
                        )
                    }
                </div>
            </Modal>

            <Modal
                open={vestingModalOpen}
                title="Set Vesting"
                onCancel={handleVestingModalCancel}
                footer={[
                    <div className="d-flex justify-content-center mt-5">
                        <Button onClick={handleVesting} loading={isSetVestingLoading} disabled={!canSetVesting}>Set Vesting</Button>
                    </div>
                ]}
                // width={700}
            >
                <div>
                    <span>Initial release</span>
                    <Input
                        type="number"
                        onChange={(e) => setInitialRelease(e.target.value)}
                        placeholder="50%"
                    />
                </div>
                <div className="mt-3">
                    <span>Release percentage</span>
                    <Input
                        type="number"
                        onChange={(e) => setReleasePercentage(e.target.value)}
                        placeholder="50%"
                    />
                </div>
                <div className="mt-3">
                    <span>Release time</span>
                    <Input
                        type="number"
                        onChange={(e) => setReleaseTime(e.target.value)}
                        placeholder="7hrs"
                    />
                </div>
            </Modal>
        </div>
    )
}

export default OwnerZone