
import React from 'react'
// import { useDispatch } from 'react-redux';
import { Button, Card, Form, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Col } from 'reactstrap'
import { setContinueStepsNumber } from './../../Redux/NewAirdropContinueSteps'
import { useDispatch } from 'react-redux'

function SearchToken() {

    const dispatch = useDispatch();

    const handleNextSteps = () => {
        dispatch(setContinueStepsNumber(1))
    }

    return (
        <div className='card-border-wrap'>
            <Card className='rfc-button-card'>
                <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                    <span>Search Your Coin</span>
                    <Form>
                        <Form.Item
                            name="search_coin_name"
                        >
                            <Input
                                prefix={<SearchOutlined />}
                                // size="large"
                                placeholder='Search coin, symbol...'
                                className='mt-2'
                            />
                        </Form.Item>
                    </Form>
                    <div className="d-flex justify-content-center mt-4">
                        <Button type='primary' onClick={handleNextSteps}>
                            Next
                        </Button>
                    </div>
                </Col>
            </Card>
        </div>
    )
}

export default SearchToken