import { ethers, utils } from 'ethers'
import { configs } from './web3.config'
import { commonConfigs } from './common.configs'
import { getChainNetworkByChainId } from '../Blockchain/utils/chainList'

export const getWeb3PRovider = () => {
    const defaultChainId = process.env.REACT_APP_CHAIN_ID
    const web3configs = getChainNetworkByChainId(defaultChainId)
    //initiate the web3 instance
    const web3 = new ethers.providers.JsonRpcProvider(web3configs.rpcUrls[0], {
      name: web3configs.chainName, chainId: parseInt(web3configs.chainId, 16)
    })
    return web3
  }
// 0714085650
export const tokenApproval = async (spenderAddress, approvalTokenAddress, approvalTokenAmount, signer) => {
    try {
      
      const provider = getWeb3PRovider()
  
      const eRC20ContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
      const contractInstance = new ethers.Contract(approvalTokenAddress, eRC20ContractABI, provider)
      const contractInstanceWithSigner = contractInstance.connect(signer)
      const tokenDecimalString = await contractInstanceWithSigner.decimals()
      
      const tokenDecimals = parseInt(tokenDecimalString)
  
      //set maximum token decimals before passing to the approval function
      // const formattedApprovalAmount = parseFloat(approvalTokenAmount.toString()).toFixed(tokenDecimals)
      const approvalTokenAmountFormatted = utils.parseUnits(approvalTokenAmount.toString(), tokenDecimals)
      const approvalReceipt = await contractInstanceWithSigner.approve(spenderAddress, approvalTokenAmountFormatted)
      const result = await approvalReceipt.wait()
      console.log('result', result);
      return result
    } catch (error) {
      let errorMessage = 'Something went wrong while trying to approve the token. Please try again'
      if (error && error.message) {
        errorMessage = error.message
      }
      if (error && error.reason && error.reason !== '') {
        errorMessage = error.reason
      }
      throw errorMessage
    }
  }

  export const getWeb3ProviderInstanceByChain = (chainType = 'bsc') => {

    let webHTTPProvider = 'https://speedy-nodes-nyc.moralis.io/964f9ccc86e0173ef2d146ce/bsc/mainnet/archive';
    if (chainType === 'bsc') {
      webHTTPProvider = 'https://speedy-nodes-nyc.moralis.io/964f9ccc86e0173ef2d146ce/bsc/mainnet/archive';
    }
  
    if (chainType === 'eth') {
      webHTTPProvider = 'https://speedy-nodes-nyc.moralis.io/964f9ccc86e0173ef2d146ce/eth/mainnet/archive';
    }
  
    if (chainType === 'matic') {
      webHTTPProvider = 'https://speedy-nodes-nyc.moralis.io/964f9ccc86e0173ef2d146ce/polygon/mainnet/archive';
    }
  
    const provider = new ethers.providers.JsonRpcProvider(webHTTPProvider);
    return provider;
  
  }

  export const getBNBPrice = async () => {
    let bnbPrice = 0.0
    try {
      const provider = getWeb3PRovider()
      const pcsRouterABI = JSON.parse(commonConfigs.pancakeSwap.pcsRouterABI)
      const pcsRouterAddress = commonConfigs.commonAddresses.bsc.pcsRouterAddress
      const pcsRouterContractInstance = new ethers.Contract(pcsRouterAddress, pcsRouterABI, provider)
  
      const bnbToSell = utils.parseEther("1")
      const wBNBAddress = commonConfigs.commonAddresses.bsc.wBNBAddress
      const usdtAddress = commonConfigs.commonAddresses.bsc.bscUSDAddress
  
      const BNBPriceResponse = await pcsRouterContractInstance.getAmountsOut(bnbToSell, [wBNBAddress, usdtAddress])
      const bnbPriceFormatted = utils.formatEther(BNBPriceResponse[1])
      bnbPrice = bnbPriceFormatted.toString()
      return bnbPrice
    } catch (error) {
      console.log("ERROR while calculating BNB Price : ", error)
      return bnbPrice
    }
  }

  export const getUserBNBBalance = async (address) => {
    let bnbBalance = 0.0
    try {
      if (address) {
        const provider = getWeb3PRovider()
        const userBNBBalance = await provider.getBalance(address)
        
        const bnbBalanceFormatted = utils.formatEther(userBNBBalance)
        bnbBalance = bnbBalanceFormatted.toString()
        return bnbBalance
      } else {
        return bnbBalance
      }

    } catch (error) {
      console.log("ERROR while fetching user bnb balance : ", error)
      return bnbBalance
    }
  }

  export const airDropTokens = async (contractList, tokenAddress, signer) => {

    try {
  
      const provider = getWeb3PRovider()
      const eRC20ContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
      const contractInstance = new ethers.Contract(tokenAddress, eRC20ContractABI, provider)
      const contractInstanceWithSigner = contractInstance.connect(signer)
      const tokenDecimalString = await contractInstanceWithSigner.decimals()
      const tokenDecimals = parseInt(tokenDecimalString)
      
      const airDropContractAddress = configs.airDrop.contractAddress
      const airDropContractABI = JSON.parse(configs.airDrop.contractABI)
      const airDropContractInstance = new ethers.Contract(airDropContractAddress, airDropContractABI, provider)
      const airDropContractInstanceWithSigner = airDropContractInstance.connect(signer)
      
      const feeAmountResponse = await airDropContractInstanceWithSigner.feeAmount()
      const payableAmount = utils.formatEther(feeAmountResponse)
      
      let amountArray = []
      let walletAddressArray = []
      contractList.forEach((item) => {
        
        let amountWithDecimals = parseFloat(item.value) * (10 ** tokenDecimals)
        amountArray.push(convertSciNotationToNum(amountWithDecimals).toString())
        walletAddressArray.push(item.contractAddress)
      })
      
      const airDropReceipt = await airDropContractInstanceWithSigner.airDropTokens(
        tokenAddress, 
        walletAddressArray, 
        amountArray, {
          value: utils.parseEther(payableAmount.toString()),
          // gasLimit: String(6000000)
        })
      const result = await airDropReceipt.wait()
      console.log('result', result);
      return result
    } catch (error) {
      let errorMessage = 'Something went wrong while trying to airdrop the tokens. Please try again'
      if (error && error.message) {
        errorMessage = error.message
      }
      if (error && error.reason && error.reason !== '') {
        errorMessage = error.reason
      }
      console.log('error', error);
      throw errorMessage
      // return errorMessage
    }
  
  }

  export const  convertSciNotationToNum = (x) => {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split('e-')[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += (new Array(e + 1)).join('0');
      }
    }
    return x;
  }

  export const getMaxWalletCount = async () => {

    let maxWalletCount = 0

    try {
      const provider = getWeb3PRovider()
      
      const airDropContractAddress = configs.airDrop.contractAddress
      const airDropContractABI = JSON.parse(configs.airDrop.contractABI)
      const airDropContractInstance = new ethers.Contract(airDropContractAddress, airDropContractABI, provider)
      
      const maxWalletCountResponse = await airDropContractInstance.maxWalletsPerTransaction()
       // eslint-disable-next-line
      const maxWalletCountFormatted = maxWalletCountResponse.toString()
      
      maxWalletCount = maxWalletCountResponse

      return maxWalletCount
    } catch (error) {

      maxWalletCount = 0
      return maxWalletCount
    }
  }

  export const createNewPool = async (tokenAddress, signer) => {
    try {
      const provider = getWeb3PRovider()
      
      const poolCreateContractAddress = configs.poolCreate.contractAddress
      const poolCreateContractABI = JSON.parse(configs.poolCreate.contractABI)
      const poolCreateContractInstance = new ethers.Contract(poolCreateContractAddress, poolCreateContractABI, provider)

      const feeAmountResponse = await poolCreateContractInstance.fee()
      const payableAmount = utils.formatEther(feeAmountResponse)

      const poolCreateContractInstanceWithSigner = poolCreateContractInstance.connect(signer)

      const poolCreateReceipt = await poolCreateContractInstanceWithSigner.deployNewPool(
        tokenAddress, {
          value: utils.parseEther(payableAmount.toString()),
          // value: payableAmount
          // gasLimit: String(6000000)
        })
      const result = await poolCreateReceipt.wait()
      
      return result
      // const payableAmount = (parseFloat(feeAmountResponse.toString()) / 10 ** 18)

    } catch (error) {
      let errorMessage = 'Something went wrong while trying to create new pool. Please try again'
      if (error && error.message) {
        errorMessage = error.message
      }
      if (error && error.reason && error.reason !== '') {
        errorMessage = error.reason
      }
      console.log('error', error);
      throw errorMessage
    }
  }

  export const getERC20TokenDataByTokenAddress = async (tokenAddress) => {
    let tokenName = ''
    let tokenSymbol = ''
    let tokenDecimals = ''
    try {
      const provider = getWeb3PRovider()

      if (tokenAddress) {
        const eRC20ContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
        const contractInstance = new ethers.Contract(tokenAddress, eRC20ContractABI, provider)
        tokenName = await contractInstance.name()
        tokenSymbol = await contractInstance.symbol()
        const tokenDecimalString = await contractInstance.decimals()
        tokenDecimals = parseInt(tokenDecimalString)
  
        return {
          tokenName,
          tokenSymbol,
          tokenDecimals
        }
      }
    } catch (error) {
      return {
        tokenName,
        tokenSymbol,
        tokenDecimals
      }
    }
  }

  export const getTokenAddress = async (poolAddress) => {
    let tokenAddress = ''
    let tokenName = ''
    let tokenSymbol = ''
    let tokenDecimals = 0
    // let tokenTotalSupply = ''
    try {
      const provider = getWeb3PRovider()
      if (poolAddress) {
        const poolContractABI = JSON.parse(commonConfigs.commonPool.contractAbi)
        const contractInstance = new ethers.Contract(poolAddress, poolContractABI, provider)
        tokenAddress = await contractInstance.airdropToken()

        if (tokenAddress) {
          const eRC20ContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
          const contractInstance = new ethers.Contract(tokenAddress, eRC20ContractABI, provider)
          tokenName = await contractInstance.name()
          tokenSymbol = await contractInstance.symbol()
          // const tokenTotalSupplyResponse = await contractInstance.totalSupply()
          const tokenDecimalString = await contractInstance.decimals()
          tokenDecimals = parseInt(tokenDecimalString)

          // tokenTotalSupply = utils.parseUnits(tokenDecimalString.toString(), tokenDecimals)
          // console.log('tokenTotalSupply', tokenTotalSupply);
        }

        return {
          tokenName,
          tokenSymbol,
          tokenAddress,
          tokenDecimals
          // tokenTotalSupply
        }
      }

    } catch (error) {
      return {
        tokenName,
        tokenSymbol,
        tokenAddress,
        // tokenTotalSupply
      }
    }
  }

  export const allocationTokens = async (walletAddressArray, amountArray, poolAddress, signer) => {
    try {
      const provider = getWeb3PRovider()
      const poolContractABI = JSON.parse(commonConfigs.commonPool.contractAbi)
      const contractInstance = new ethers.Contract(poolAddress, poolContractABI, provider)
      const contractInstanceWithSigner = contractInstance.connect(signer)

      const setAllocationReceipt = await contractInstanceWithSigner.setAllocations(walletAddressArray, amountArray
        // , {gasLimit: 60000}
        )
      const result = await setAllocationReceipt.wait()
      console.log('result', result);
      return result

    } catch (error) {
      let errorMessage = 'Something went wrong while trying to set allocation. Please try again'
      if (error && error.message) {
        errorMessage = error.message
      }
      if (error && error.reason && error.reason !== '') {
        errorMessage = error.reason
      }
      throw errorMessage
    }
  }

  export const getTotalRequiredBalanceForAirdrop = async (poolAddress, tokenDecimals) => {
    let totalRequiredBalance = 0
    try {
      const provider = getWeb3PRovider()
      const poolContractABI = JSON.parse(commonConfigs.commonPool.contractAbi)
      const contractInstance = new ethers.Contract(poolAddress, poolContractABI, provider)
      const totalRequiredForAirdropResponse = await contractInstance.totalRequiredForAirdrop()
      totalRequiredBalance = utils.formatUnits(totalRequiredForAirdropResponse, tokenDecimals)

      return totalRequiredBalance
    } catch (error) {
      return totalRequiredBalance
    }
  }

  export const approveStartTime = async (poolAddress, tokenAddress, amount, signer) => {
    try {
      const provider = getWeb3PRovider()
  
      const eRC20ContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
      const contractInstance = new ethers.Contract(tokenAddress, eRC20ContractABI, provider)
      const contractInstanceWithSigner = contractInstance.connect(signer)
      const tokenDecimalString = await contractInstance.decimals()
      const tokenDecimals = parseInt(tokenDecimalString)

      const approvalTokenAmountFormatted = utils.parseUnits(amount.toString(), tokenDecimals)
      const approvalReceipt = await contractInstanceWithSigner.approve(poolAddress, approvalTokenAmountFormatted)
      const result = await approvalReceipt.wait()
      console.log('result', result);
      return result

    } catch (error) {
      let errorMessage = 'Something went wrong while trying to start the airdrop. Please try again'
      if (error && error.message) {
        errorMessage = error.message
      }
      if (error && error.reason && error.reason !== '') {
        errorMessage = error.reason
      }
      console.log('error', error);
      throw errorMessage
    }
  }

  export const changeStart = async (poolAddress, startTime, signer) => {
    try {
      const provider = getWeb3PRovider()
      const poolContractABI = JSON.parse(commonConfigs.commonPool.contractAbi)
      const contractInstance = new ethers.Contract(poolAddress, poolContractABI, provider)
      const contractInstanceWithSigner = contractInstance.connect(signer)

      const startAirdropReceipt = await contractInstanceWithSigner.startAirDrop(startTime
        // , {gasLimit: 60000}
        )
      const result = await startAirdropReceipt.wait()
      console.log('result', result);
      return result

    } catch (error) {
      let errorMessage = 'Something went wrong while trying to set startAirdrop. Please try again'
      if (error && error.message) {
        errorMessage = error.message
      }
      if (error && error.reason && error.reason !== '') {
        errorMessage = error.reason
      }
      throw errorMessage
    }
  }

  export const setVestings = async (poolAddress, initialRelease, releasePercentage, releaseTime, signer) => {
    try {
      console.log('lll');
      const provider = getWeb3PRovider()
      const poolContractABI = JSON.parse(commonConfigs.commonPool.contractAbi)
      const contractInstance = new ethers.Contract(poolAddress, poolContractABI, provider)
      const contractInstanceWithSigner = contractInstance.connect(signer)
      
      const setVestingReceipt = await contractInstanceWithSigner.setVesting(initialRelease, releasePercentage, releaseTime
        // , {gasLimit: 60000}
        )
      const result = await setVestingReceipt.wait()
      console.log('result', result);
      return result

    } catch (error) {
      let errorMessage = 'Something went wrong while trying to set vesting. Please try again'
      if (error && error.message) {
        errorMessage = error.message
      }
      if (error && error.reason && error.reason !== '') {
        errorMessage = error.reason
      }
      throw errorMessage
    }
  }

  export const getUserTokenDetails = async (walletAddress, poolAddress, signer) => {
    let userTokensResponse = []
    let isVesting = false
    try {
      const provider = getWeb3PRovider()
      const poolContractABI = JSON.parse(commonConfigs.commonPool.contractAbi)
      const contractInstance = new ethers.Contract(poolAddress, poolContractABI, provider)
      userTokensResponse = await contractInstance.userTokes(walletAddress)
      isVesting =  await contractInstance.isVesting()

      return {
        userTokensResponse,
        isVesting
      }

    } catch (error) {
      let errorMessage = 'Something went wrong while trying to set get user tokens details. Please try again'
      if (error && error.message) {
        errorMessage = error.message
      }
      if (error && error.reason && error.reason !== '') {
        errorMessage = error.reason
      }
      throw errorMessage
    }
  }
  export const getPoolStartTime = async (poolAddress) => {
    let poolStartTime = 0
    try {
      const provider = getWeb3PRovider()
      const poolContractABI = JSON.parse(commonConfigs.commonPool.contractAbi)
      const contractInstance = new ethers.Contract(poolAddress, poolContractABI, provider)

      const poolStartResponse = await contractInstance.startTime()
      poolStartTime = poolStartResponse.toString()

      return poolStartTime

    } catch (error) {
      let errorMessage = 'Something went wrong while trying to get pool start time. Please try again'
      if (error && error.message) {
        errorMessage = error.message
      }
      if (error && error.reason && error.reason !== '') {
        errorMessage = error.reason
      }
      throw errorMessage
    }
  }

//   export const getTokenList = async (walletAddress) => {
//     try {
//       const provider = getWeb3PRovider()

//       // const provider = new ethers.providers.Web3Provider(window.ethereum);

// const assets = await provider.send('wallet_watchAsset', {
//   type: 'ERC20',
//   options: {
//     address: null, // Set to null to get all assets
//     symbol: null, // Set to null to get all assets
//     decimals: null, // Set to null to get all assets
//     image: null, // Set to null to get all assets
//   },
// });

// console.log('assets', assets);
//       const tokens = await getTokensInWallet(provider, walletAddress);
//       console.log('tokens', tokens);

//     } catch (error) {
      
//     }
//   }
// export const getTokensInWallet = async (provider, address) => {
//     const tokens = {};
//     const filter = {
//       address,
//       topics: [ethers.utils.id("Transfer(address,address,uint256)")]
//     };
//     const assets = await provider.send('wallet_watchAsset', {
//       type: 'ERC20',
//       options: {
//         address: null, // Set to null to get all assets
//         symbol: null, // Set to null to get all assets
//         decimals: null, // Set to null to get all assets
//         image: null, // Set to null to get all assets
//       },
//     });
//     const logs = await provider.getLogs(filter);
// console.log('logs', assets);
//     for (const log of logs) {
//       const tokenAddress = log.address;
//       if (!tokens[tokenAddress]) {
//         const eRC20ContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
//         const tokenContract = new ethers.Contract(tokenAddress, eRC20ContractABI, provider);
//         const symbol = await tokenContract.symbol();
//         const name = await tokenContract.name();
//         const balance = await tokenContract.balanceOf(address);
//         const balanceFormatted = ethers.utils.formatUnits(balance, await tokenContract.decimals());
//         tokens[tokenAddress] = { symbol, name, balance: balanceFormatted };
//       } else {
//         const balance = await ethers.utils.bigNumberify(tokens[tokenAddress].balance).add(log.data);
//         // tokens[tokenAddress].balance = ethers.utils.formatUnits(balance, await tokenContract.decimals());
//       }
//     }

//     return { tokens: Object.values(tokens) };
//   };

export const userTokenWithdraw = async (poolAddress, signer) => {
  try {
    const provider = getWeb3PRovider()
    const poolContractABI = JSON.parse(commonConfigs.commonPool.contractAbi)
    const contractInstance = new ethers.Contract(poolAddress, poolContractABI, provider)

    const contractInstanceWithSigner = contractInstance.connect(signer)

    const withdrawTokensReceipt = await contractInstanceWithSigner.withdrawTokens()
    const result = await withdrawTokensReceipt.wait()

    return result

  } catch (error) {
    let errorMessage = 'Something went wrong while trying to token withdraw. Please try again'
      if (error && error.message) {
        errorMessage = error.message
      }
      if (error && error.reason && error.reason !== '') {
        errorMessage = error.reason
      }
      throw errorMessage
  }
}

export const getPoolDetailsAndToken = async (poolAddress) => {

  let airdropToken = ''
  let totalRequiredForAirdrop = 0.0
  let numberOfUsers = 0
  let startTime = 0
  let tokenName = ''

  try {
    const provider = getWeb3PRovider()
    const poolContractABI = JSON.parse(commonConfigs.commonPool.contractAbi)
    const contractInstance = new ethers.Contract(poolAddress, poolContractABI, provider)
    const airdropTokenResponse = await contractInstance.airdropToken()

    const eRC20ContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
    const eRC20ContractInstance = new ethers.Contract(airdropTokenResponse, eRC20ContractABI, provider)
    const tokenDecimalString = await eRC20ContractInstance.decimals()
    const tokenNameResponse = await eRC20ContractInstance.name()
    const tokenDecimals = parseInt(tokenDecimalString)

    const totalRequiredForAirdropResponse = await contractInstance.totalRequiredForAirdrop()
    const totalRequiredForAirdropFormatted = utils.formatUnits(totalRequiredForAirdropResponse.toString(), tokenDecimals)

    const numberOfUsersResponse = await contractInstance.getNumberOfUsers()
    const numberOfUsersFormatted = numberOfUsersResponse.toString()

    const startTimeResponse = await contractInstance.startTime()
    const startTimeFormatted = startTimeResponse.toString()

    airdropToken = airdropTokenResponse
    totalRequiredForAirdrop = totalRequiredForAirdropFormatted
    numberOfUsers = numberOfUsersFormatted
    startTime = startTimeFormatted
    tokenName = tokenNameResponse

    return {
      airdropToken,
      totalRequiredForAirdrop,
      numberOfUsers,
      startTime,
      tokenName
    }

  } catch (error) {
    let errorMessage = 'Something went wrong while trying to token details. Please try again'
      if (error && error.message) {
        errorMessage = error.message
      }
      if (error && error.reason && error.reason !== '') {
        errorMessage = error.reason
      }
      throw errorMessage
  }
}

export const getAllocations = async (poolAddress) => {
  try {
    const provider = getWeb3PRovider()
    const poolContractABI = JSON.parse(commonConfigs.commonPool.contractAbi)
    const contractInstance = new ethers.Contract(poolAddress, poolContractABI, provider)
    const walletsResponse = await contractInstance.getWallets()
    const amountsResponse = await contractInstance.getAmounts()
    const airdropTokenResponse = await contractInstance.airdropToken()

    const eRC20ContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
    const eRC20ContractInstance = new ethers.Contract(airdropTokenResponse, eRC20ContractABI, provider)
    const tokenDecimalString = await eRC20ContractInstance.decimals()
    const tokenDecimals = parseInt(tokenDecimalString)

    let formattedAmounts = []
    amountsResponse.forEach(element => {
      const formattedAmountValue = utils.formatUnits(element.toString(), tokenDecimals)
      formattedAmounts.push(formattedAmountValue)
    });

  const objectArray = [];

  for (let i = 0; i < walletsResponse.length; i++) {
    const obj = {
      walletAddress: walletsResponse[i],
      amount: formattedAmounts[i]
    };
    objectArray.push(obj);
  }

    
    return objectArray
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to fetch wallet or token list in allocations. Please try again'
      if (error && error.message) {
        errorMessage = error.message
      }
      if (error && error.reason && error.reason !== '') {
        errorMessage = error.reason
      }
      throw errorMessage
  }
}

export const getPoolTokenAmount = async (poolAddress) => {
  let poolTokenBalance = 0.0
  try {
    const provider = getWeb3PRovider()

    const poolContractABI = JSON.parse(commonConfigs.commonPool.contractAbi)
    const contractInstance = new ethers.Contract(poolAddress, poolContractABI, provider)
    const airdropTokenResponse = await contractInstance.airdropToken()

    const eRC20ContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
    const eRC20ContractInstance = new ethers.Contract(airdropTokenResponse, eRC20ContractABI, provider)
    const poolBalanceResponse = await eRC20ContractInstance.balanceOf(poolAddress)
    const formattedPoolBalance = poolBalanceResponse.toString()
    const tokenDecimalString = await eRC20ContractInstance.decimals()
    const tokenDecimals = parseInt(tokenDecimalString)
    
    poolTokenBalance = utils.formatUnits(formattedPoolBalance, tokenDecimals)

    return poolTokenBalance

  } catch (error) {
    let errorMessage = 'Something went wrong while trying to fetch pool token amount. Please try again'
      if (error && error.message) {
        errorMessage = error.message
      }
      if (error && error.reason && error.reason !== '') {
        errorMessage = error.reason
      }
      throw errorMessage
  }
}

export const getAirdropCreateFee = async () => {
  let airdropCreateFee = 0.0
  try {
      const provider = getWeb3PRovider()

      const poolCreateContractAddress = configs.poolCreate.contractAddress
      const poolCreateContractABI = JSON.parse(configs.poolCreate.contractABI)
      const poolCreateContractInstance = new ethers.Contract(poolCreateContractAddress, poolCreateContractABI, provider)

      const feeAmountResponse = await poolCreateContractInstance.fee()
      airdropCreateFee = utils.formatEther(feeAmountResponse)

    return airdropCreateFee

  } catch (error) {
    let errorMessage = 'Something went wrong while trying to fetch pool create fee amount. Please try again'
      if (error && error.message) {
        errorMessage = error.message
      }
      if (error && error.reason && error.reason !== '') {
        errorMessage = error.reason
      }
      throw errorMessage
  }
}
