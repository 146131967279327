import React from 'react'
import { Button, Card, Form } from 'antd'
import { useDispatch } from 'react-redux';
// import './CreateAirdrop.css'
import { setContinueStepsNumber } from '../../Redux/NewAirdropContinueSteps'
import { Row } from 'reactstrap';

function FinaliseAirdrop() {

    const dispatch = useDispatch();

    const handleBackSteps = () => {
        dispatch(setContinueStepsNumber(1))
        console.log('');
    }

    const handleNextSteps = () => {
        dispatch(setContinueStepsNumber(2))
    }

    return (
        <>
            <div className='card-border-wrap'>
                <Card className='rfc-button-card'>

                    <Form
                        className='mt-3 '
                        name="token_information"
                        // onFinish={onFinish}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Row>
                            <div className='text-center' style={{ marginTop: '30px' }}>
                                <Button style={{ marginRight: '8px' }} >
                                    Deposit Tokens
                                </Button>
                                <Button type="primary" >
                                    Finalise Airdrop
                                </Button>
                            </div>
                        </Row>

                        {/* <div className='flex'>
                            <div className='text-center'>
                                <Button type="primary">
                                    Deposit Tokens
                                </Button>
                            </div>

                            <div className='text-center'>
                                <Button type="primary" >
                                    Finalise Airdrop
                                </Button>
                            </div>
                        </div> */}
                    </Form>
                    <Row>
                        <div className='text-center' style={{ marginTop: '30px' }}>
                            <Button style={{ marginRight: '8px' }} onClick={handleBackSteps}>
                                Back
                            </Button>
                            <Button type="primary" onClick={handleNextSteps}>
                                Next
                            </Button>
                        </div>
                    </Row>
                </Card>
            </div>
        </>
    )
}

export default FinaliseAirdrop