import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    createStepNumber: 0
};

export const createAirdropStepsSlice = createSlice({
    name: "createAirdropSteps",
    initialState,
    reducers: {
        setCreateStepNumber: (state, action) => {
            state.createStepNumber = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setCreateStepNumber } = createAirdropStepsSlice.actions;

export default createAirdropStepsSlice.reducer;