import React, { useState } from "react";
import { Row, Col } from 'reactstrap'
import TokenDetails from "../../Components/Airdrop/Details/TokenDetails";
import TokenAllocation from '../../Components/Airdrop/Details/TokenAllocation'
import ContributionsDetails from '../../Components/Airdrop/Details/ContributionsDetails'
import OwnerZone from '../../Components/Airdrop/Details/OwnerZone'
import { useWeb3React } from '@web3-react/core'
import { Spin } from "antd";
import { useSelector } from "react-redux";

function AirdropDetails() {

    const { account } = useWeb3React()
    const { tokenDecimals } = useSelector((state) => state.selectedAirdropDetail)
    // const [tokenDecimals, setTokenDecimals] = useState(0)
    // const [tokenAddress, setTokenAddress] = useState('')
    const [ownerAddress, setOwnerAddress] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    
    return (
        <div>
            <div className='text-light container mt-5'>
                <div className='d-flex justify-content-center align-items-center'>
                    <div className='col-lg-10 col-md-10 col-sm-12'>
                        <Row>
                            <Col lg="7" md="7" sm="12">
                                <TokenDetails
                                    // setTokenDecimals={setTokenDecimals}
                                    // setTokenAddress={setTokenAddress}
                                    setIsLoading={setIsLoading}
                                    setOwnerAddress={setOwnerAddress}
                                />
                            </Col>
                            <Col lg="5" md="5" sm="12">
                                {
                                    (parseInt(tokenDecimals) === 0) ? (
                                        <div className="d-flex justify-content-center">
                                            <Spin size="small" />
                                        </div>
                                    ) : (
                                        <ContributionsDetails
                                            // tokenDecimals={tokenDecimals}
                                            // tokenAddress={tokenAddress}
                                            isLoading={isLoading}
                                        />
                                    )
                                }
                                {/* <ContributionsDetails
                                            // tokenDecimals={tokenDecimals}
                                            // tokenAddress={tokenAddress}
                                            isLoading={isLoading}
                                        /> */}
                                
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col lg="7" md="7" sm="12">
                                <TokenAllocation />
                            </Col>
                            
                            {
                                isLoading ? (
                                    <div className="d-flex justify-content-center">
                                        <Spin size="small" />
                                    </div>
                                ) : (
                                    <>
                                        {
                                            (ownerAddress === account) ? (
                                                <Col lg="5" md="5" sm="12">
                                                    <OwnerZone
                                                        // tokenDecimals={tokenDecimals}
                                                        // tokenAddress={tokenAddress}
                                                    />
                                                </Col>
                                            ) : (<></>)
                                        }
                                    </>
                                )
                            }
                            
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AirdropDetails