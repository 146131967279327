import React from "react";
import { useSelector } from 'react-redux'
import { Card, Table } from 'antd'

function MultisendResult(params) {

    const { contractList } = useSelector((state) => state.csvDataList)

    const columns = [
        {
          title: 'Wallet Address',
          dataIndex: 'contractAddress',
          render: (text) => <a href={`https://testnet.bscscan.com/address/${text}`} target={'_blank'} rel="noreferrer">{text}</a>,
            ellipsis: true,
            width: '70%',
        },
        {
          title: 'Amount',
          dataIndex: 'value',
          align: 'right',
        },
    ]


    return (
        <div>
            <div className='col-lg-12 mt-4'>
                <div className='card-border-wrap'>
                    <Card className='rfc-button-card'>
                        <h5 className="mb-4">Transactions</h5>
                        {/* {contractList.map((item) => (
                            <div className='d-flex justify-content-between align-items-start mx-2 approve-token-list'>
                                <span className='contract-address'>{item.contractAddress}</span>
                                <span className='send-value'>{item.value}</span>
                            </div>
                        ))} */}
                        <Table 
                            className="mt-4" 
                            dataSource={contractList} 
                            columns={columns}
                            showHeader={false}
                        />
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default MultisendResult