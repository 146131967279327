import React, { useEffect, useState } from 'react'
import './NewAirdrops.css'
import { Button, List, Steps } from 'antd';
import AirdropTokenInfo from '../../Components/NewAirdrops/AirdropTokenInfo';
import FinaliseAirdrop from '../../Components/NewAirdrops/FinaliseAirdrop';
import SearchToken from '../../Components/NewAirdrops/SearchToken';
import { useSelector } from 'react-redux';



function NewAirdrops() {

    const [currentStep, setCurrentStep] = useState(0);

    const steps = [
        { title: 'Search your coin', head: 'Step', content: <SearchToken /> },
        { title: 'Fill in the form', head: 'Step', content: <AirdropTokenInfo /> },
        { title: 'Finalise your airdrop!', head: 'Step', content: <FinaliseAirdrop /> },
    ]

    const { continueStepsNumber } = useSelector((state) => state.newAirdropContinueSteps)

    useEffect(() => {
        if (continueStepsNumber) {
            setCurrentStep(continueStepsNumber);
        }
    }, [continueStepsNumber])

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
        head: item.head,
    }));

    const onChange = (value) => {
        setCurrentStep(value);
    };

    const data = [
        '1. Creating an Airdrop with RugfreeCoins costs 0.2 BNB Flat Fee + 0.01 BNB per 100 winners.',
        '2. You will create your own Airdrop, and then deposit tokens to the smart contract.',
        '3. When the Aidrop expires, RugfreeCoins will announce the winners and they will be able to claim the rewards through the website.',
        '4. RugfreeCoins will provide you the list of all the participants.',
    ];

    return (
        <div className='mt-5 text-light mb-5'>
            <div className='container-fluid col-lg-9 col-md-10'>
                <div className='mb-4'>
                    <span className="page-topic ">Organize an Airdrop in RugfreeCoins</span>
                    <List className='mt-3'
                        itemLayout="vertical"
                        dataSource={data}
                        renderItem={(item) =>
                            <List.Item
                                style={{
                                    border: "none",
                                    paddingLeft: "20px",
                                    lineHeight: "1.2",
                                    textIndent: "-10px"
                                }}>{item}
                            </List.Item>}
                    />
                </div>

                <>
                    <div className='mt-2' >
                        <Steps current={currentStep} items={items} onChange={onChange} />
                        <div className="steps-content mt-3">{steps[currentStep].content}</div>
                    </div>
                </>

            </div>
        </div>
    )
}

export default NewAirdrops