import { Card, Table } from "antd";
import React, { useEffect, useState } from "react";
import { getAllocations } from '../../../Blockchain/web3.service'
import { useParams } from "react-router-dom";

function TokenAllocation() {

    const params = useParams();

    const [isAllocationsLoading, setIsAllocationsLoading] = useState(false)
    const [allocations, setAllocations] = useState([])

    const fetchAllocations = async () => {
        setIsAllocationsLoading(true)

        try {
            const allocationResult = await getAllocations(params?.airdropAddress)

            setAllocations(allocationResult)
            setIsAllocationsLoading(false)
        } catch (error) {
            setIsAllocationsLoading(false)
        }
    }

    useEffect(() => {
        fetchAllocations()
        // eslint-disable-next-line
    }, [])
    
    const columns = [
        {
          title: 'Wallet Address',
          dataIndex: 'walletAddress',
          render: (text) => <a href={`https://testnet.bscscan.com/address/${text}`} target={'_blank'} rel="noreferrer">{text}</a>,
        //   responsive: ['lg'],
        ellipsis: true
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
        //   ellipsis: true
        //   responsive: ['md'],
        },
      ];

    return (
        <div>
            <div className='card-border-wrap'>
                <Card className='rfc-button-card'>
                    <span>Allocations({allocations.length})</span>
                    <Table 
                        className="mt-4" 
                        dataSource={allocations} 
                        loading={isAllocationsLoading} 
                        columns={columns}
                        />
                        
                </Card>
            </div>
        </div>
    )
}

export default TokenAllocation