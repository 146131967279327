import React, { useEffect, useState } from "react";
import { Button, Card, notification, Spin, Tag, Progress } from "antd";
import { 
    getUserTokenDetails,
    getPoolStartTime,
    userTokenWithdraw,
    getTotalRequiredBalanceForAirdrop,
    getPoolTokenAmount
 } from '../../../Blockchain/web3.service'
import { useParams } from "react-router-dom";
import { useWeb3React } from '@web3-react/core'
import { utils } from "ethers";
import { useSelector } from "react-redux";
import { DateTime } from 'luxon'
import Countdown from 'react-countdown'
import NumberFormat from 'react-number-format'

function ContributionsDetails() {

    // const { isLoading } = props
    const { tokenDecimals, isTokenMetaDataLoading, tokenSymbol, isOwnerZoneChangesDone } = useSelector((state) => state.selectedAirdropDetail)
    const currentUTC = parseInt(DateTime.now().toSeconds())

    const params = useParams();
    const { library, account } = useWeb3React()

    const [statusColor, setStatusColor] = useState('success')
    const [currentStatus, setCurrentStatus] = useState('Live')
    const [isStartTimeLoading, setIsStartTimeLoading] = useState(false)
    const [poolStartTime, setPoolStartTime] = useState(0)
    const [isShowCountdown, setIsShowCountdown] = useState(false)
    const [countdownTimerTime, setCountdownTimerTime] = useState(DateTime.now())
    const [showVestingCountdown, setShowVestingCountdown] = useState(false)

    const [isTokenDetailsLoading, setIsTokenDetailsLoading] = useState(false)
    const [haveToClaimTokens, setHaveToClaimTokens] = useState(false)
    const [claimableTokenAmount, setClaimableTokenAmount] = useState(0.0)

    const [msgToShow, setMsgToShow] = useState('')

    const [isClaimTokenLoading, setIsClaimTokenLoading] = useState(false)
    const [minimumBalanceRequired, setMinimumBalanceRequired] = useState(0.0)
    const [poolTokenAmount, setPoolTokenAmount] = useState(0.0)
    const [tokenPercentage, setTokenPercentage] = useState(0.0)
    const [yourClaimedAmount, setYourClaimedAmount] = useState(0.0)
    const [yourAllocation, setYourAllocation] = useState(0.0)

    const fetchMinimumTokenBalanceToAirdrop = async () => {
        setIsStartTimeLoading(true)
        try {
            const result = await getTotalRequiredBalanceForAirdrop(params?.airdropAddress, tokenDecimals)
            if (result) {
                setMinimumBalanceRequired(parseInt(result))
                setIsStartTimeLoading(false)
            }
        } catch (error) {
            setMinimumBalanceRequired(0)
            setIsStartTimeLoading(false)
        }
    }

    const fetchPoolTokenAmount = async () => {
        setIsStartTimeLoading(true)
        try {
            const result = await getPoolTokenAmount(params?.airdropAddress)
            setPoolTokenAmount(parseInt(result))
            setIsStartTimeLoading(false)
        } catch (error) {
            setPoolTokenAmount(0)
            setIsStartTimeLoading(false)
        }
    }

    const fetchPoolStartTime = async () => {
        setIsStartTimeLoading(true)
        try {
            const startTimeResponse = await getPoolStartTime(params?.airdropAddress)
            const poolStartTime = parseInt(startTimeResponse)
            if (poolStartTime > 0) {
                if (poolStartTime > currentUTC) {
                    setCountdownTimerTime(DateTime.fromSeconds(poolStartTime))
                    setIsShowCountdown(true)
                    setStatusColor('warning')
                    setCurrentStatus('Ongoing')
                } else {
                    setIsShowCountdown(false)
                    setStatusColor('success')
                    setCurrentStatus('Live')
                    if (parseFloat(tokenDecimals) > 0) {
                        fetchUserTokenDetails()
                    }
                }
            } else {
                setStatusColor('error')
                setCurrentStatus('Pending')
            }
            if (poolStartTime > 0) {
                const startTimeFormatted = DateTime.fromSeconds(parseInt(poolStartTime))
                const startTimeUTC = startTimeFormatted.toFormat("yyyy.LL.dd HH:mm")
                setPoolStartTime(startTimeUTC)
            } else {
                setPoolStartTime('Not start yet')
            }
            
            setIsStartTimeLoading(false)
        } catch (error) {
            setPoolStartTime(0)
            setIsStartTimeLoading(false)
        }

    }

    const fetchUserTokenDetails = async () => {
        setIsTokenDetailsLoading(true)
        try {
            if (!isTokenMetaDataLoading) {
                const userTokensDetailResponse = await getUserTokenDetails(account, params?.airdropAddress, library.getSigner())
                
                if (userTokensDetailResponse) {
                    const formattedClaimableTokens = utils.formatUnits(userTokensDetailResponse.userTokensResponse[0], tokenDecimals)
                    setYourAllocation(formattedClaimableTokens)
                    const formattedYourClaimedAmount = utils.formatUnits(userTokensDetailResponse.userTokensResponse[3], tokenDecimals)
                    setYourClaimedAmount(formattedYourClaimedAmount)
                    
                    const isClaimedTokens = userTokensDetailResponse.userTokensResponse[1]
                    const nextClaimTime = userTokensDetailResponse.userTokensResponse[2].toString()
                    const remainingToClaim = userTokensDetailResponse.userTokensResponse[4]
                    const isVesting = userTokensDetailResponse.isVesting
                    
                    if (parseFloat(formattedClaimableTokens) > 0) {

                        if (isVesting) {
                            if (isClaimedTokens) {
                                if (parseFloat(remainingToClaim) > 0) {
                                    if (parseInt(nextClaimTime) > currentUTC) {
                                        setCountdownTimerTime(DateTime.fromSeconds(parseInt(nextClaimTime)))
                                        setShowVestingCountdown(true)
                                        setHaveToClaimTokens(false)
                                    } 
                                } else {
                                    setHaveToClaimTokens(false)
                                    setMsgToShow('Tokens already claimed')
                                }
                            } else {
                                setHaveToClaimTokens(true)
                                setClaimableTokenAmount(formattedClaimableTokens)
                            }
                        } else {
                            if (isClaimedTokens) {
                                setMsgToShow('Tokens already claimed')
                                setHaveToClaimTokens(false)
                            } else {
                                setMsgToShow('You have to claim token')
                                setHaveToClaimTokens(true)
                                setClaimableTokenAmount(formattedClaimableTokens)
                            }
                        }
                    } else {
                        setHaveToClaimTokens(false)
                        setClaimableTokenAmount(0.0)
                        setMsgToShow('You have not allocated tokens')
                    }
                    setIsTokenDetailsLoading(false)
                }
            }
            
        } catch (error) {
            setIsTokenDetailsLoading(false)
        }
    }

    const handleClaimTokens = async () => {
        setIsClaimTokenLoading(true)
        try {
            const tokenWithdrawResponse = await userTokenWithdraw(params?.airdropAddress, library.getSigner())
            if (tokenWithdrawResponse) {
                fetchUserTokenDetails()
                console.log('tokenWithdrawResponse', tokenWithdrawResponse);
                setIsClaimTokenLoading(false)
                notification['success']({
                    message: 'Success',
                    description:
                        'Tokens withdraw successfully',
                })
            }
        } catch (error) {
            setIsClaimTokenLoading(false)
            notification['error']({
                message: 'Error',
                description:
                    'Something wrong with tokens withdraw',
            })
        }
    }

    useEffect(() => {
        if (parseFloat(tokenDecimals) > 0) {
            fetchPoolStartTime()
            fetchMinimumTokenBalanceToAirdrop()
            fetchPoolTokenAmount()
        }
        // eslint-disable-next-line
    }, [tokenDecimals])

    useEffect(() => {
        if (isOwnerZoneChangesDone) {
            fetchPoolStartTime()
            fetchMinimumTokenBalanceToAirdrop()
            fetchPoolTokenAmount()
        }
    }, [isOwnerZoneChangesDone])

    useEffect(() => {
        if (minimumBalanceRequired > 0 && poolTokenAmount > 0) {
            const tokenPercentage = (poolTokenAmount / minimumBalanceRequired) * 100

            setTokenPercentage(tokenPercentage.toFixed(2))
        } else {
            setTokenPercentage(0)
        }
    }, [minimumBalanceRequired, poolTokenAmount])

    return (
        <div>
            <div className='card-border-wrap'>
                <Card className='rfc-button-card'>
                    {
                        isStartTimeLoading ? (
                            <div className="d-flex justify-content-center">
                                <Spin size="small" />
                            </div>
                        ) : (
                            <>
                                <div className="d-flex justify-content-between">
                                    <span>Contributions</span>
                                    <Tag color={statusColor} className="">{currentStatus}</Tag>
                                </div>
                                {
                                    isShowCountdown ? (
                                        <div className="d-flex justify-content-center mt-3">
                                            <div>
                                                <div>
                                                    <span>You have to wait</span>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <Countdown
                                                        date={countdownTimerTime ? countdownTimerTime.toString() : DateTime.now().toString()}
                                                        renderer={props => (
                                                            <div>
                                                            {props.formatted.days}:
                                                            {props.formatted.hours}:
                                                            {props.formatted.minutes}:
                                                            {props.formatted.seconds}
                                                            </div>
                                                        )}
                                                        zeroPadTime={2}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {
                                                isTokenDetailsLoading ? (
                                                    <div className="d-flex justify-content-center">
                                                        <Spin size="small" />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {
                                                            msgToShow !== '' && 
                                                            <div className="mt-3">
                                                                <span>{msgToShow}</span>
                                                            </div>
                                                        }
                                                        {
                                                            showVestingCountdown ? (
                                                                <div className="d-flex justify-content-center mt-3">
                                                                    <Countdown
                                                                        date={countdownTimerTime ? countdownTimerTime.toString() : DateTime.now().toString()}
                                                                        renderer={props => (
                                                                            <div>
                                                                            {props.formatted.days}:
                                                                            {props.formatted.hours}:
                                                                            {props.formatted.minutes}:
                                                                            {props.formatted.seconds}
                                                                            </div>
                                                                        )}
                                                                        zeroPadTime={2}
                                                                    />
                                                                </div>
                                                            ) : <></>
                                                        }
                                                        {
                                                            haveToClaimTokens ? (
                                                                <Button type="primary" className="my-4" onClick={handleClaimTokens} block loading={isClaimTokenLoading}>Claim your {claimableTokenAmount} tokens</Button>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }
                                <div>
                                    <Progress
                                        percent={tokenPercentage}
                                        showInfo={false}
                                        strokeColor="#b160f0"
                                        status="active"
                                        trailColor="#d9d9d9"
                                    />
                                    <div className="d-flex justify-content-between progress-bar-values">
                                        <NumberFormat
                                            value={minimumBalanceRequired}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={5}
                                        />
                                        <NumberFormat
                                            value={poolTokenAmount}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={5}
                                            suffix={tokenSymbol}
                                        />
                                    </div>
                                </div>
                                <div className="contribution-details mt-5">
                                    <div className="d-flex justify-content-between">
                                        <span>Start Time</span>
                                        <span>{poolStartTime}</span>
                                    </div>
                                    <div className="bottom-divider"></div>
                                    <div className="d-flex justify-content-between mt-1">
                                        <span>Your Allocation</span>
                                        <NumberFormat
                                            value={yourAllocation}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            suffix={' ' + tokenSymbol}
                                        />
                                    </div>
                                    <div className="bottom-divider"></div>
                                    <div className="d-flex justify-content-between  mt-1">
                                        <span>Your Claimed</span>
                                        <NumberFormat
                                            value={yourClaimedAmount}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            suffix={' ' + tokenSymbol}
                                        />
                                    </div>
                                </div>
                            </>
                            
                        )
                    }
                
                </Card>
            </div>
        </div>
    )
}

export default ContributionsDetails