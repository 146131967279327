import { Layout } from 'antd';
import React, { useState, useEffect } from 'react';
import NavBar from './Components/NavBar/NavBar';
import Pages from './Pages/Pages';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined
  } from '@ant-design/icons';
import SiderComponent from './Components/Sider/SiderComponent';
import { useSelector } from "react-redux";
import Logo from './images/rfc.png'

function LayoutView() {

    const { Content, Footer } = Layout;

    const { margin } = useSelector((state) => state.margin)
    const [marginVariable, setMarginVariable] = useState('side-menu-margin-extended')
    // eslint-disable-next-line
    const [width, setWidth] = useState('width-240')
    const [contentWidth, setContentWidth] = useState('container')

    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        if (margin) {
          setMarginVariable('side-menu-margin-extended')
          setWidth('width-240')
          setContentWidth('container')
        } else {
          setMarginVariable('side-menu-margin')
          setWidth('width-80')
          setContentWidth('col-lg-10')
        }
      }, [margin])
    
    return (
        <Layout className="layout">
            <NavBar >
                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: 'trigger',
                    onClick: () => setCollapsed(!collapsed),
                })}
            </NavBar>
            
            <Layout className="site-layout">
                <div className='side-menu'>
                    <SiderComponent />
                </div>
                <Layout className={marginVariable}>
                    <Content
                        style={{minHeight: '100vh'}} className="background-color"
                    >
                        <div className={`mt-5 mb-5 mx-auto ${contentWidth} content-margin`}>
                            <div>
                                <Pages />
                            </div>
                        </div>
                    </Content>
                </Layout>
                 
            </Layout>
            <Footer className="text-center" style={{background: '#1c1d30'}}>
                <div>
                    <span>COPYRIGHT © {new Date().getFullYear()} <a href="https://www.rugfreecoins.com/" target="_blank" rel="noreferrer"><img src={Logo} alt="main_logo" style={{width: '25px', marginLeft: '5px', marginRight: '5px'}} />Rugfreecoins</a></span>
                </div>
                <div>
                    <span> All rights Reserved</span>
                </div>
                
            </Footer>
        </Layout>
    )
}

export default LayoutView