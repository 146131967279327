import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    continueStepsNumber: 0
}

export const newAirdropContinueSlice = createSlice({
    name: 'newAirdropContinueSteps',
    initialState,
    reducers: {
        setContinueStepsNumber: (state, action) => {
            state.continueStepsNumber = action.payload
        }
    }
});

// Action creators are generated for each case reducer function
export const { setContinueStepsNumber } = newAirdropContinueSlice.actions;

export default newAirdropContinueSlice.reducer;