import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Spin, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import { setCreateStepNumber } from '../../../Redux/CreateAirdropSteps'
import WAValidator from 'wallet-address-validator'
import { setTokenAddress, setSymbolOfToken } from '../../../Redux/CreateNewAirdrop'
import { getERC20TokenDataByTokenAddress, getAirdropCreateFee } from '../../../Blockchain/web3.service'
import { useWeb3React } from '@web3-react/core'
import axios from 'axios';

function AirdropCreate() {
	const web3React = useWeb3React()
	const { account } = web3React
	// const [ tokenAddress, setTokenAddress ] = useState('');
	// eslint-disable-next-line
	const [ hasValidTokenAddress, setHasValidTokenAddress ] = useState(false);
	// const [ showTokenDetailsForm, setShowTokenDetailsForm ] = useState(false);
	// eslint-disable-next-line
	const [tokenInputValidationStatus, setTokenInputValidationStatus] = useState('success');
	// eslint-disable-next-line
	const [tokenInputValidationMessage, setTokenInputValidationMessage] = useState('');
	const [isTokenDetailsLoading, setIsTokenDetailsLoading] = useState(false);
	const [tokenName, setTokenName] = useState('');
	const [tokenSymbol, setTokenSymbol] = useState('');
	const [tokenDecimals, setTokenDecimals] = useState(0);
	const [isFetchTokenDetails, setIsFetchTokenDetails] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [isToken, setIsToken] = useState(false);
	const [isTokenListLoading, setIsTokenListLoading] = useState(false);
	const [tokenList, setTokenList] = useState([])
	const [currentNetworkID, setCurrentNetworkID] = useState(0)
	const [poolCreateFee, setPoolCreateFee] = useState(0)
	const [isPoolFeeLoading, setIsPoolFeeLoading] = useState(false);

    const dispatch = useDispatch()

	const { tokenAddress, symbolOfToken } = useSelector((state) => state.createNewAirdrop)

	useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on('chainChanged', event => {
                const chainID = parseInt(event.toString(), 16)
                setCurrentNetworkID(chainID)
            });

            window.ethereum.request({ method: "eth_chainId" })
                .then(chainId => {
                    setCurrentNetworkID(parseInt(chainId, 16))
                })

        }

    }, [])

	useEffect(
		() => {
			if (tokenAddress) {
				const valid = WAValidator.validate(tokenAddress, 'ETH')
				if (valid) {
					setHasValidTokenAddress(true);
					setTokenInputValidationStatus('success')
					setTokenInputValidationMessage('')
					fetchTokenDetails()
				} else {
					setHasValidTokenAddress(false);
					setTokenInputValidationStatus('error')
					setTokenInputValidationMessage('Invalid token address')
				}
				
			} else {
				setHasValidTokenAddress(false);
				setTokenInputValidationStatus('error')
				setTokenInputValidationMessage('Token address cannot be blank')
			}
		},
		// eslint-disable-next-line
		[ tokenAddress ]
	);

	useEffect(() => {
        const fetchTokenList = async () => {
            setIsTokenListLoading(true)
            try {
                if (account && currentNetworkID) {
                    let networkIdForApi = ''
                    if (currentNetworkID === 97) {
                        networkIdForApi = '0x61'
                    } else if (currentNetworkID === 56) {
                        networkIdForApi = '0x38'
                    }
                    let config = {
                        method: 'get',
                        url: `${process.env.REACT_APP_API_BASE_URL}/${account}/erc20?chain=${networkIdForApi}`,
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': `${process.env.REACT_APP_API_KEY}`
                        }
                    };
                    const response = await axios(config)
                    if (response.status === 200) {
                        const payload = response.data
                        if (payload) {
                            setTokenList(payload)
                        } else {
                            setTokenList([])
                        }
                    }
                }

            } catch (error) {
                console.log("ERROR while fetching active pools from API ", error)
            }
            setIsTokenListLoading(false)
        }

        fetchTokenList()
    }, [currentNetworkID, account])

	useEffect(() => {
        const fetPoolCreateFee = async () => {
            setIsPoolFeeLoading(true)
            try {
                const poolCreateFeeResponse = await getAirdropCreateFee()
            	setPoolCreateFee(poolCreateFeeResponse)

            } catch (error) {
                console.log("ERROR while fetching pool create fee ", error)
				setPoolCreateFee(0.0)
            }
            setIsPoolFeeLoading(false)
        }

        fetPoolCreateFee()
    }, [])

	const handleChange = (value) => {
        const splitValue = value.split(",")
		// dispatch(setTokenAddress(e.target.value))
        // dispatch(setSelectedTokenBalance(formattedValue))
        dispatch(setSymbolOfToken(splitValue[0]))
        dispatch(setTokenAddress(splitValue[3]))

    };

	const fetchTokenDetails = async () => {
		try {
		  setIsTokenDetailsLoading(true)
		  const tokenDataResponse = await getERC20TokenDataByTokenAddress(tokenAddress)
		  
		  setIsTokenDetailsLoading(false)
		  if (tokenDataResponse?.tokenName !== '') {
			setIsFetchTokenDetails(true)
			setIsToken(true)
		  } else {
			setErrorMessage('This is not a Token')
			setIsToken(false)
		  }
			
		  //set token details
		  setTokenName(tokenDataResponse?.tokenName)
		  setTokenSymbol(tokenDataResponse?.tokenSymbol)
		  setTokenDecimals(tokenDataResponse?.tokenDecimals)
	
		} catch (error) {
			setIsTokenDetailsLoading(false)
			setIsFetchTokenDetails(false)
			setIsToken(false)
		}
	  }

	const handleNext = () => {
        dispatch(setCreateStepNumber(1))
		// setShowTokenDetailsForm(true);
	};

	const onFinish = (values) => {
		dispatch(setCreateStepNumber(1))
	  }

	return (
		<div>
			<div className="col-lg-12 mt-4">
				<div className="card-border-wrap">
					<Form
						name="project_info"
						onFinish={onFinish}
						initialValues={{
							tokenAddress: tokenAddress
						}}
					>
						<Card className="rfc-button-card">
							<div>
								<div>
									<span className="text-validation-error">(*) is required.</span>
									<div>
										<span>
											Token Address<span className="required-field-warning">*</span>
										</span>
										{/* <Form.Item
											name="tokenAddress"
											onChange={(e) => dispatch(setTokenAddress(e.target.value))}
											rules={[
												{
												required: true,
												message: 'Please enter the airdrop address!',
												},
											]}
											validateStatus={tokenInputValidationStatus}
											help={tokenInputValidationMessage}
											value={tokenAddress}
										>
											<Input
												className="mt-2" 
												lang='en'
												placeholder='Ex: Rugfreecoins' />
										</Form.Item> */}
										<Select
											style={{
												width: '100%',
											}}
											onChange={handleChange}
											placeholder={account ? 'Select your Token' : 'Connect your wallet'}
											notFoundContent={isTokenListLoading ? <Spin size="small" /> : null}
											loading={isTokenListLoading}
											defaultValue={tokenAddress ? symbolOfToken + ' - ' + tokenAddress : ''}
										>
											{tokenList.map((item) => (
												<Select.Option
													key={item?.token_address}
													value={item?.symbol +
														',' + item?.balance +
														',' + item?.decimals +
														',' + item?.token_address}
												>
													{item?.symbol} - {item?.token_address}
												</Select.Option>
											))}
										</Select>
										<span className="fee-detail-text">Create airdrop fee: {isPoolFeeLoading ? <Spin size="small" /> : poolCreateFee}</span>
									</div>
									
								</div>
								
							{
								isTokenDetailsLoading ? 
									<Spin size="small" /> : 
									<>
										{
											isToken ? 
												<div>
													<div className='d-flex justify-content-between mt-3'>
														<span>Name</span>
														<span>{tokenName}</span>
													</div>
													<div className="bottom-divider"></div>
													<div className='d-flex justify-content-between mt-1'>
														<span>Symbol</span>
														<span>{tokenSymbol}</span>
													</div>
													<div className="bottom-divider"></div>
													<div className='d-flex justify-content-between mt-1'>
														<span>Decimals</span>
														<span>{tokenDecimals}</span>
													</div>
													<div className="bottom-divider"></div>
													
												</div> : 
												<div className='d-flex justify-content-start'>
													<span className="text-validation-error">{errorMessage}</span>
												</div>
										}
									</>
									
							}
								
								<div className="d-flex justify-content-center mt-4">
									<Button onClick={handleNext} disabled={!isFetchTokenDetails} type='primary'>
										Next
									</Button>
								</div>
							</div>
						</Card>
					</Form>
				</div>
			</div>
		</div>
	);
}

export default AirdropCreate;
