import { configureStore } from "@reduxjs/toolkit"
import WalletConnectReducer from "./WalletConnect"
import RoiModalReducer from "./RoiModal"
import BlockChainChangeReducer from "./BlockChainChange"
import HomeStepsReducer from './HomeSteps'
import CreateAirdropStepsReducer from './CreateAirdropSteps'
import CsvDataListReducer from './CsvDataList'
import CreateAirdropReducer from './CreateNewAirdrop'
import SidemenuMarginReducer from "./SidemenuMargin"
import AirDropDetailsReducer from "./AirdropDetails"
import NewAirdropContinueStepsReducer from "./NewAirdropContinueSteps"

export const Store = configureStore({
  reducer: {
    connectModalState: WalletConnectReducer,
    roiModalState: RoiModalReducer,
    blockchainModalState: BlockChainChangeReducer,
    homeSteps: HomeStepsReducer,
    csvDataList: CsvDataListReducer,
    createAirdropSteps: CreateAirdropStepsReducer,
    createNewAirdrop: CreateAirdropReducer,
    margin: SidemenuMarginReducer,
    selectedAirdropDetail: AirDropDetailsReducer,
    newAirdropContinueSteps: NewAirdropContinueStepsReducer
  }
});