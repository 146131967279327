import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './NavBar.css'
import Logo from '../../images/rfc.png'
import { Button, Modal, Drawer } from 'antd'
// import { GlobalState } from '../../GlobalState'
import Metamask from '../../images/MetaMask_Fox.png'
import WalletConnect from '../../images/walletconnect.png'
import { getEllipsisTxt } from '../../Helpers/Formatters'
import { useSelector, useDispatch } from "react-redux";
import { open, close } from '../../Redux/WalletConnect'
import { useWeb3React } from '@web3-react/core'
import { injected } from '../../Blockchain/connectors/metaMask'
import { wcConnector } from '../../Blockchain/connectors/walletConnect'
import { Copy, CheckCircle, ExternalLink } from 'react-feather'
// import BSCLogo from '../../images/blockchains/binance.png'
// import ETHLogo from '../../images/blockchains/etherum.png'
import { MenuOutlined, DoubleLeftOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import MenuComponent from '../Menu/MenuComponent'
import { change } from '../../Redux/SidemenuMargin';

function NavBar() {

  // const state = useContext(GlobalState)
  // eslint-disable-next-line
  const [showConnectModal, setShowConnectModal] = useState(false)
  const { modal_opened } = useSelector((state) => state.connectModalState)
  const { margin } = useSelector((state) => state.margin)
  // eslint-disable-next-line
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [isDisconnectVisible, setIsDisconnectVisible] = useState(false)
  // const [selectedNetwork, setSelectedNetwork] = useState('bsc')

  const dispatch = useDispatch();
  const [addressCopy, setAddressCopy] = useState(false)

  const web3React = useWeb3React()
  const { account, deactivate } = web3React

  // const { Option } = Select;

  const showModal = () => {
    setShowConnectModal(true);
  };

  const handleOk = () => {
    setShowConnectModal(false);
  };

  const handleCancel = () => {
    setShowConnectModal(false);
    setIsDisconnectVisible(false)
  };
  const showDisconnectModal = () => {
    setIsDisconnectVisible(true)
  }
  const showDrawer = () => {
    setShowMobileMenu(true);
  };

  const closeDrawer = () => {
    setShowMobileMenu(false);
  };
  const handleMetamaskConnect = async () => {
    try {
      await web3React.activate(injected)
      const loginPayload = {
        provider: 'injected',
        isLoggedIn: true
      }
      window.localStorage.setItem('userData', JSON.stringify(loginPayload))
    } catch (error) {

      const loginPayload = {
        provider: 'injected',
        isLoggedIn: false
      }
      window.localStorage.setItem('userData', JSON.stringify(loginPayload))
    } finally {
      dispatch(close())
    }
  }

  const handleWalletConnectConnect = async () => {
    try {
      await web3React.activate(wcConnector)
      const loginPayload = {
        provider: 'walletconnect',
        isLoggedIn: true
      }
      window.localStorage.setItem('userData', JSON.stringify(loginPayload))
    } catch (error) {
      const loginPayload = {
        provider: 'walletconnect',
        isLoggedIn: false
      }
      window.localStorage.setItem('userData', JSON.stringify(loginPayload))
      console.error('error connecting to wallet-connect ', error)
    } finally {
      dispatch(close())
    }
  }
  const handleDisconnect = () => {
    try {
      window.localStorage.removeItem("userData")
      deactivate()
      handleCancel()
    } catch (error) {
      console.error("error while logout from user ", error)
    }
  }

  const copyAddress = () => {
    setAddressCopy(true)
    navigator.clipboard.writeText(account)
  }

  // const handleChange = (value) => {
    
  //   if (value === 'eth') {
  //     handleNetworkChange(1)
  //   } else {
  //     handleNetworkChange(56)
  //   }
  //   setSelectedNetwork(value)
  //   // toggleTheme()
  //   // dispatch(handleChainSelect(value))
  //   // dispatch(changedChain(true))
  //   // setDashboard(value)
  //   console.log(value);
  // };

  // const handleNetworkChange = async (networkId) => {
  //   const chainId = `0x${Number(networkId).toString(16)}`
  //   const provider = window.ethereum
  //   try {
  //     if (provider) {
  //       await provider.request({
  //         method: 'wallet_switchEthereumChain',
  //         params: [{ chainId: chainId }],
  //       })
  //     }
  //   } catch (switchError) {
  //     // This error code indicates that the chain has not been added to MetaMask.
  //     if (switchError.code === 4902) {
  //       console.log("This network is not available in your metamask, please add it")
  //     }
  //     console.log("Failed to switch to the network")
  //   }
  // }

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('chainChanged', event => {
        const chainID = parseInt(event.toString(), 16)
        console.log('chainID', chainID);
        if (chainID === 56) {
          // setBscSelected(true)
          // setEthSelected(false)
          // dispatch(handleChainSelect('bsc'))
          window.localStorage.setItem('user_selected_chain', 'bsc')
        }

        if (chainID === 1) {
          // setEthSelected(true)
          // setBscSelected(false)
          // dispatch(handleChainSelect('eth'))
          window.localStorage.setItem('user_selected_chain', 'eth')
        }
        // setCurrentNetworkID(chainID)
      });

      window.ethereum.request({ method: "eth_chainId" })
        .then(chainId => {
          // setCurrentNetworkID(parseInt(chainId, 16))
        })
    }
  }, [])

  // useEffect(() => {
  //   if (selectedNetwork) {
  //     console.log('selectedNetwork', selectedNetwork);
  //   }
  // }, [selectedNetwork])

  // const TokenClaimMenu = (
  //   <div className='menu-dropdown'>
  //     <Link to="/airdrop/create" >
  //       <div className='menu-dropdown-topics'>Create Airdro</div>
  //     </Link>
  //     <Link to="/airdrops">
  //       <div className='menu-dropdown-topics'>Airdrop List</div>
  //     </Link>
  //   </div>
  // )

  const handleSideMenu = () => {
    // if (hamburgerClass === "hamburger") {
    //   setHamburgerClass("hamburger is-active")
    // } else {
    //   setHamburgerClass("hamburger")
    // }
    dispatch(change())
  }

  return (
    <nav className="navbar d-flex justify-content-between sticky-top navbar-light bg-blur-nav pt-3 pb-3">
        {/* mobile menu handler */}
        <div className='d-flex hide-on-pc'>
          <MenuOutlined style={{ fontSize: '18px', marginRight: '5px', color: '#b160f0' }} className='mobile-menu my-auto' onClick={showDrawer} />
          <div className="hide-on-pc">
            <Link to="/"><img src={Logo} alt="main_logo" style={{width: '30px', marginLeft: '5px'}} /></Link>
          </div>
        </div>
        
        {/* site logo to show on large devices in left side -> will hidden on small devices */}
        <div className="navbar-brand hide-on-mobile" style={{marginLeft: '15px'}}>
          <Link to="/" style={{textDecoration: 'none'}}><img src={Logo} alt="main_logo" style={{width: '40px'}} />
            {
              margin ? <span className='title-text mt-1'>RUGFREE<span className='coins-title'>COINS</span></span> : <></>
            }
            
          </Link>

          {
            margin ? <LeftCircleOutlined className='menu-change-icon'  onClick={() => handleSideMenu()} /> : <RightCircleOutlined  className='menu-change-icon'  onClick={() => handleSideMenu()} />
          }
        </div>
        
        {/* wallet connect button to show on small devices in left side -> will hide on large devices */}
        <div className="hide-on-pc" style={{marginLeft: '10px'}}>
            {
              !account ?
              <Button type="primary" className='rfc-primary-button' onClick={showModal}>Connect</Button>
              :
              <Button className='rfc-primary-button' onClick={showModal}>{getEllipsisTxt(account, 3)}</Button>
            }
        </div>

        {/* site logo to show on small devices in center -> will hide on large devices */}
        
        
        {/* wallet connect button & hamburger menu icon to show on large devices in right side -> wallet connect button will hide on small devices */}
        <div className='my-auto d-flex mx-4 hide-on-mobile'>
          {/* <div className='hide-on-mobile'>
            <Link to="/">
              <Button className='menu-text-button' type="text">Bulksend</Button>
            </Link>
            <Tooltip placement="bottom" title={TokenClaimMenu} color='#001529'>
              <Button className='menu-text-button' type="text">Token Claim</Button>
            </Tooltip>
          </div> */}
          
          {/* <div className='mx-2'>
            <Button
              className='rfc-primary-button'
              style={{ marginRight: '5px' }}>
              <div className='d-flex'>
                <Select
                  size='large'
                  bordered={false}
                  showArrow={false}
                  placement="topLeft"
                  style={{
                    width: '100%',
                  }}
                  placeholder="select chain"
                  value={selectedNetwork}
                  onChange={handleChange}
                  getPopupContainer={trigger => trigger.parentNode}
                  // optionLabelProp="label"
                >
                  <Option value="bsc" label={<span className='fw-bold'><img src={BSCLogo} alt="bsc-logo" style={{ width: '20px' }} /> BSC</span>}>
                    
                      <div className="demo-option-label-item ">
                        <span role="img" aria-label="bsc">
                          <img src={BSCLogo} alt="bsc-logo" style={{ width: '20px', marginRight: '5px' }} />
                        </span>
                        <span className='hide-on-small-devices'>
                            BSC
                        </span>
                      </div>
                  </Option>
                  <Option value="eth" label={<span className='fw-bold'><img src={ETHLogo} alt="eth-logo" style={{ width: '15px' }} /> ETH</span>}>
                    
                      <div className="demo-option-label-item">
                        <span role="img" aria-label="bsc">
                          <img src={ETHLogo} alt="eth-logo" style={{ width: '20px', marginRight: '5px' }} />
                        </span>
                        <span className='hide-on-small-devices'>
                          ETH
                        </span>
                        
                      </div>
                  </Option>
                </Select>
              </div>
            </Button>
          </div> */}
            {
              !account ?
              <Button className='hide-on-mobile rfc-primary-button' onClick={() => dispatch(open())}>Connect Wallet</Button>
              :
              <Button className='hide-on-mobile rfc-primary-button' onClick={() => showDisconnectModal(true)}>{getEllipsisTxt(account, 5)}</Button>
            }
            
        </div>
        
        <Drawer
          placement="left"
          onClose={closeDrawer}
          open={showMobileMenu}
          closable={false}
          width="250px"
          style={{ zIndex: '9999' }}
        >
          <div style={{ marginLeft: '-8px' }}>

            <div className='pb-1' style={{ marginTop: '-17px' }}>
              <DoubleLeftOutlined style={{ fontSize: '18px', marginRight: '5px', color: '#b160f0' }} className='mobile-menu-svg' onClick={closeDrawer} />

              <Link to="/" style={{textDecoration: 'none'}}  onClick={closeDrawer}>
                <img src={Logo} alt="rfc-main-logo" className='rfc-main-logo' style={{ marginLeft: '' }} />
              </Link>
            </div>

            <MenuComponent
              setShowMobileMenu={setShowMobileMenu}
              // showMobileMenu={showMobileMenu}
            />

          </div>
        </Drawer>

        {/* Wallet connect modal will show up when wallet connect button clicked */}
        <Modal title="Connect to a wallet" open={modal_opened} onOk={() => dispatch(close())} onCancel={() => dispatch(close())} footer={false}>
          <Button className='col-12 rfc-primary-button' size="large" onClick={handleMetamaskConnect}>
            <img src={Metamask} alt="metamask_logo" style={{width: '30px', marginRight: '10px'}} /> 
            Connect with Metamask
          </Button>
          <Button className='col-12 rfc-primary-button mt-3' size="large" onClick={handleWalletConnectConnect}>
            <img src={WalletConnect} alt="metamask_logo" style={{width: '30px', marginRight: '10px'}} /> 
            Connect with WalletConnect
          </Button>
        </Modal>

        <Modal title='Connected Wallet' open={isDisconnectVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>

          <div className="d-flex justify-content-between">
            <p className="text-muted hide-on-mobile">Connected</p>
            <p className="text-lg-end fw-bold cursor" onClick={handleDisconnect}>
              <Button className="btn disconnect-button fw-bold" style={{ marginRight: '15px' }} onClick={handleDisconnect}>Disconnect</Button>
            </p>
          </div>

          <div className='user-account-address-container'>
            <Button
              className="rfc-primary-button d-flex primary-text col-12"
              style={{ marginRight: '15px' }}>
              <img src={Logo} style={{ width: '20px', marginRight: '15px' }} alt='' />
              {/* <span className='small'>{getEllipsisTxt(account, 12)}</span> */}
              <span className='small'>{account}</span>
            </Button>
          </div>

          <div className="row mt-3">
            {
              !addressCopy &&
              <p className="fw-bold col-8 hide-on-mobile cursor" onClick={copyAddress} style={{ cursor: "pointer" }}>
                <Copy size={18} color="#009edd" />
                <span style={{ marginLeft: '5px' }} className="span-button">Copy address</span>
              </p>
            }
            {
              addressCopy &&
              <p className="fw-bold col-8 hide-on-mobile">
                <CheckCircle size={18} color="#009edd" />
                <span style={{ marginLeft: '5px' }} className='span-button'>Copied</span>
              </p>
            }

            <p className="text-lg-end col-md-4 col-sm-6 fw-bold">
              <a
                href={`${process.env.REACT_APP_BLOCK_EXPLORER}/address/${account}`}
                target="_blank"
                className="col-lg-4 col-md-6 col-sm-6  fw-bold"
                rel="noreferrer">
                <ExternalLink size={18} color="#009edd" />
                <span
                  style={{ marginLeft: '5px', textDecoration: "none", cursor: "pointer" }} className='span-button'>
                    View on explorer
                </span>
              </a>
            </p>
          </div>

        </Modal>
    </nav>
  )
}

export default NavBar