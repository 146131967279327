import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tokenAddress: '',
    tokenDecimals: 0,
    tokenName: '',
    tokenSymbol: '',
    isTokenMetaDataLoading: false,
    isOwnerZoneChangesDone: false
};

export const airDropDetailSlice = createSlice({
    name: "selectedAirdropDetail",
    initialState,
    reducers: {
        setTokenAddress: (state, action) => {
            state.tokenAddress = action.payload
        },
        setTokenDecimals: (state, action) => {
            state.tokenDecimals = action.payload
        },
        setTokenName: (state, action) => {
            state.tokenName = action.payload
        },
        setTokenSymbol: (state, action) => {
            state.tokenSymbol = action.payload
        },
        setIsTokenMetaDataLoading: (state, action) => {
            state.isTokenMetaDataLoading = action.payload
        },
        setIsOwnerZoneChangesDone: (state, action) => {
            state.isOwnerZoneChangesDone = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { 
    setTokenAddress,
    setTokenDecimals,
    setTokenName,
    setTokenSymbol,
    setIsTokenMetaDataLoading,
    setIsOwnerZoneChangesDone
} = airDropDetailSlice.actions;

export default airDropDetailSlice.reducer;