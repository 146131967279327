import React, { useState } from 'react'
import { CalendarOutlined, DollarCircleOutlined, RocketOutlined, SearchOutlined, SnippetsOutlined, TrophyOutlined } from '@ant-design/icons'
import { Button, Card, DatePicker, Form, Input, List, Steps, Switch } from 'antd'
import { Col, Row } from 'reactstrap'
import './NewAirdrop.css'
import { useDispatch } from 'react-redux'
import { setContinueStepsNumber } from '../../Redux/NewAirdropContinueSteps'

function AirdropTokenInfo() {

    const dispatch = useDispatch();

    const handleNextSteps = () => {
        dispatch(setContinueStepsNumber(2))
    }

    const handlePrevSteps = () => {
        dispatch(setContinueStepsNumber(0))
        console.log('setContinueStepsNumber', setContinueStepsNumber);
    }

    const [isTwitter, setIsTwitter] = useState(false)
    const [isTelegram, setIsTelegram] = useState(false)

    const onChangeIsTwitter = (value) => {
        if (value) {
            setIsTwitter(true)
        } else {
            setIsTwitter(false)
        }
    }

    const onChangeIsTelegram = (value) => {
        if (value) {
            setIsTelegram(true)
        } else {
            setIsTelegram(false)
        }
    }


    return (
        <>
            <div className='card-border-wrap'>
                <Card className='rfc-button-card'>
                    <Form
                        className='mt-3'
                        name="token_information"
                        // onFinish={onFinish}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <h5 className='mb-3'>Token Information</h5>

                        <Row className='mt-3'>
                            {/* <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                <span className='small'>Search Your Coin</span>
                                <Form.Item
                                    name="search_coin_name"
                                >
                                    <Input
                                        prefix={<SearchOutlined />}
                                        size="large"
                                        placeholder='Search coin, symbol...'
                                    />
                                </Form.Item>
                            </Col> */}

                            <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                <span >Start of the Airdrop</span>
                                <Form.Item
                                    name="airdrop_start_date"
                                    // className='ant-input-affix-wrapper'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'The start of the airdrop field is required!',
                                        },
                                    ]}
                                >
                                    <DatePicker

                                        size="large"
                                        className='w-100 mt-2 datepicker-size ant-input-affix-wrapper'
                                    // className='w-100 ant-input-affix-wrapper'
                                    // onChange={onChangePresaleDate}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                <span >Total Airdrop Amount</span>
                                <Form.Item
                                    name="total_airdrop_amount"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Airdrop amount field is required!',
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<DollarCircleOutlined />}
                                        // size="large"
                                        placeholder='100000'
                                        className='mt-2'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                <span>Number of days (Min 5, Max 20)</span>
                                <Form.Item
                                    name="actual_price"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'The number of days field is required!',
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<CalendarOutlined />}
                                        // size="large"
                                        placeholder="6"
                                        min={5}
                                        type="number"
                                        className="mt-2"
                                        onWheel={() => document.activeElement.blur()}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                <span>Number of Winners</span>
                                <Form.Item
                                    name="no_of_winners"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Number of Winners field is required!',
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<TrophyOutlined />}
                                        // size="large"
                                        placeholder='100000'
                                        className="mt-2"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className='mt-4 mb-1'>
                            <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                <Col sm="12" xs="12">
                                    <Form.Item
                                        name="is_twitter"

                                    >
                                        <span className='mt-2 mb-2' >Add Follow Twitter Step? </span>
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            onChange={onChangeIsTwitter}
                                        />
                                    </Form.Item>
                                </Col>
                                {
                                    isTwitter &&
                                    <Row className='mt-2'>
                                        <Col sm="12" xs="12">
                                            <span className='mt-2'>Twitter Link</span>
                                            <Form.Item
                                                name="twitter_link"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Twitter Link field is required!',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    prefix={<SnippetsOutlined />}
                                                    // size="large"
                                                    placeholder='Twitter Username'
                                                    className='mt-2'
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                            <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                <Col sm="12" xs="12">
                                    <Form.Item
                                        name="is_twitter"
                                    >
                                        <span className='mt-2 mb-2' >Add Follow Telegram Step? </span>
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            onChange={onChangeIsTelegram}
                                        />
                                    </Form.Item>
                                </Col>
                                {
                                    isTelegram &&
                                    <Row className='mt-2'>
                                        <Col sm="12" xs="12">
                                            <span className='mt-2'>Telegram Link</span>
                                            <Form.Item
                                                name="telegram_link"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Telegram Link field is required!',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    prefix={<SnippetsOutlined />}
                                                    // size="large"
                                                    placeholder='Telegram Username'
                                                    className='mt-2'
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>
                        <Row >
                            <div className='text-center' style={{ marginTop: '30px' }}>
                                <Button style={{ marginRight: '8px' }} onClick={handlePrevSteps}>
                                    Back
                                </Button>
                                <Button type="primary" onClick={handleNextSteps}>
                                    Next
                                </Button>
                            </div>
                        </Row>
                        {/* <Form.Item>
                            <div className='text-center mt-4'>
                                <Button className='connect-metamask' >
                                    Connect to create Airdrop
                                </Button>
                            </div>
                        </Form.Item> */}

                    </Form>
                </Card>
            </div>
        </>
    )
}

export default AirdropTokenInfo