import React, { useEffect, useState } from 'react';
import { Button, Input, Card, Form, notification } from 'antd';
import { Row, Col } from 'reactstrap';
import {
	PictureOutlined,
	GlobalOutlined,
	FacebookOutlined,
	TwitterOutlined,
	GithubOutlined,
	InstagramOutlined,
	RedditOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3React } from '@web3-react/core'
import { 
    setAirdropTitle, 
    setLogoUrl,
    setWebsite,
    setFacebookUrl,
    setGithubUrl,
    setInstagramUrl,
    setRedditUrl,
    setDescription,
    setDiscordUrl,
    setTelegramUrl,
    setTwitterUrl
 } from '../../../Redux/CreateNewAirdrop';
 import { createNewPool } from '../../../Blockchain/web3.service'
 import axios from 'axios'
 import { useNavigate } from 'react-router-dom'

function TokenDetailsForm() {

	const dispatch = useDispatch();
    const { library, account } = useWeb3React()
    const navigate = useNavigate()

    const key = 'updatable';

    const [isCreateLoading, setIsCreateLoading] = useState(false)
    const [canProceedToCreate, setCanProceedToCreate] = useState(false)
    const [logoURLValidationMessage, setLogoURLValidationMessage] = useState(null)
    const [logoURLValidationStatus, setLogoURLValidationStatus] = useState(null)

    const [websiteValidationMessage, setWebsiteValidationMessage] = useState(null)
    const [websiteValidationStatus, setWebsiteValidationStatus] = useState(null)

    const [everyFieldFilled, setEveryFieldFilled] = useState(false)

	const { 
        airdropTitle, 
        logoUrl,
        website,
        facebookUrl,
        githubUrl,
        instagramUrl,
        redditUrl,
        description,
        discordUrl,
        telegramUrl,
        twitterUrl,
        tokenAddress
     } = useSelector((state) => state.createNewAirdrop);

	const { TextArea } = Input;

	const onFinish = (values) => {
		// dispatch(setCreateStepNumber(1))
		console.log(values);
	};

    const handleCreateAirdrop = async () => {
        setIsCreateLoading(true)
        try {
            if (tokenAddress && account) {

                const result = await createNewPool(tokenAddress, library.getSigner())

                console.log("POOL CREATE RESULT ", result)

                const poolAddress = result?.events[0]?.address

                const payload = {
                    "poolAddress": poolAddress,
                    "ownerAddress": account,
                    "title": airdropTitle,
                    "logo": logoUrl,
                    "webSite": website,
                    "facebook": facebookUrl,
                    "twitter": twitterUrl,
                    "github": githubUrl,
                    "telegram": telegramUrl,
                    "instagram": instagramUrl,
                    "discord": discordUrl,
                    "reddit": redditUrl,
                    "description": description
                }

                let config = {
                    method: 'post',
                    url: `${process.env.REACT_APP_BACKEND_API_BASE_URL}/airdrop`,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': `${process.env.REACT_APP_BACKEND_API_KEY}`
                    },
                    data: JSON.stringify(payload)
                };

                const apiResponse = await axios(config)
                console.log("API RESULT ", apiResponse.data)

                if (apiResponse) {
                    notification['success']({
                        key,
                        message: 'Success',
                        description: 'Pool has been created.',
                    })

                    navigate('/airdrops')
                }
                setIsCreateLoading(false)
            }
            
        } catch (error) {
            setIsCreateLoading(false)
        }
    }

    const isValidUrl = (url) => {
        try {
            return Boolean(new URL(url));
        }
        catch (e) {
            return false;
        }
    }

    const isValidImageURL = (url) => {
        try {
            var regExp = /^https?:\/\/.*\/.*\.(jpeg|jpg|gif|png|JPG|JPEG|GIF|PNG)\??.*$/gmi
            if (url.match(regExp)) {
                return true;
            }
        } catch (error) {
            return false
        }
        // return (url.match(/(?:https?:\/\/)?\.(jpeg|jpg|gif|png|JPG|JPEG|GIF|PNG)$/) != null)
    }

    useEffect(() => {
        if (logoUrl) {
            if (isValidImageURL(logoUrl)) {
                setLogoURLValidationStatus('success')
                setLogoURLValidationMessage(null)
                
            } else {
                setLogoURLValidationStatus('error')
                setLogoURLValidationMessage('Invalid logo URL. Enter the direct URL to the logo in .png, .jpg or .gif format')
            }
        }
    }, [logoUrl])

    useEffect(() => {
        if (websiteValidationStatus === 'success' && logoURLValidationStatus === 'success' && airdropTitle !== '') {
            setCanProceedToCreate(true)
        } else {
            setCanProceedToCreate(false)
        }
    }, [logoURLValidationStatus, websiteValidationStatus, airdropTitle])

    useEffect(() => {
        if (website) {
            if (isValidUrl(website)) {
                setWebsiteValidationStatus('success')
                setWebsiteValidationMessage(null)
            } else {
                setWebsiteValidationStatus('error')
                setWebsiteValidationMessage("Invalid website url")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [website])

    useEffect(() => {
        if (
            airdropTitle !== '' &&
            logoUrl !== '' &&
            website  !== null
        ) {
          setEveryFieldFilled(true)
        } else {
          setEveryFieldFilled(false)
        }
      }, [airdropTitle, logoUrl, website])

	return (
		<div>
			<div className="col-lg-12 mt-4">
				<div className="card-border-wrap">
					<Form
						name="tokenDetails"
						onFinish={onFinish}
						initialValues={{
							airdropTitle: airdropTitle,
                            logoUrl: logoUrl,
                            website: website,
                            facebookUrl: facebookUrl,
                            githubUrl: githubUrl,
                            instagramUrl: instagramUrl,
                            redditUrl: redditUrl,
                            description: description,
                            discordUrl: discordUrl,
                            telegramUrl: telegramUrl,
                            twitterUrl: twitterUrl
						}}
					>
					<Card className="rfc-button-card">
						<div>
							<span className="text-validation-error">(*) is required.</span>
							<div>
								<Row className="mt-3">
									<Col lg="12" md="12" sm="12">
										<div className="">
											<span>Airdrop Title</span>
											<Form.Item
                                                name="airdropTitle"
                                                onChange={(e) => dispatch(setAirdropTitle(e.target.value))}
                                                rules={[
                                                    {
                                                    required: true,
                                                    message: 'Please enter the title!',
                                                    },
                                                ]}
                                                value={airdropTitle}
                                            >
                                                <Input
                                                    className="mt-2"
                                                    lang="en"
                                                    placeholder="Ex: Rugfreecoins"
                                                />
											</Form.Item>
										</div>
									</Col>
								</Row>
								<Row className="mt-4">
									<Col lg="6" md="6" sm="12">
										<div className="">
											<span>
												Logo URL<span className="required-field-warning">*</span>
											</span>
                                            <Form.Item
                                                name="logoUrl"
                                                onChange={(e) => dispatch(setLogoUrl(e.target.value))}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter the Logo URL'
                                                    }
                                                ]}
                                                value={logoUrl}
                                                validateStatus={logoURLValidationStatus}
                                                help={logoURLValidationMessage}
                                            >
                                                <Input
                                                    
                                                    className="mt-2"
                                                    placeholder="Ex: https://..."
                                                    prefix={<PictureOutlined style={{ marginRight: '10px' }} />}
                                                    
                                                />
                                            </Form.Item>
											
										</div>
									</Col>
									<Col lg="6" md="6" sm="12">
										<div className="mobile-top-margin-form">
											<span>
												Website<span className="required-field-warning">*</span>
											</span>
                                            <Form.Item
                                                name="website"
                                                onChange={(e) => dispatch(setWebsite(e.target.value))}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter the Logo URL'
                                                    }
                                                ]}
                                                value={website}
                                                validateStatus={websiteValidationStatus}
                                                help={websiteValidationMessage}
                                            >
                                                <Input
                                                    className="mt-2"
                                                    placeholder="Ex: https://..."
                                                    prefix={<GlobalOutlined style={{ marginRight: '10px' }} />}
                                                    
                                                />
                                            </Form.Item>
										</div>
									</Col>
								</Row>
								<Row className="mt-4">
									<Col lg="6" md="6" sm="12">
										<div className="">
											<span>Facebook</span>
                                            <Form.Item
                                                name="facebookUrl"
                                                onChange={(e) => dispatch(setFacebookUrl(e.target.value))}
                                                value={facebookUrl}
                                            >
                                                <Input
                                                    className="mt-2"
                                                    placeholder="Ex: https://facebook.com/..."
                                                    prefix={<FacebookOutlined style={{ marginRight: '10px' }} />}
                                                    
                                                />
                                            </Form.Item>
										</div>
									</Col>
									<Col lg="6" md="6" sm="12">
										<div className="mobile-top-margin-form">
											<span>Twitter</span>
                                            <Form.Item
                                                name="twitterUrl"
                                                onChange={(e) => dispatch(setTwitterUrl(e.target.value))}
                                                value={twitterUrl}
                                            >
                                                <Input
                                                    className="mt-2"
                                                    placeholder="Ex: https://twitter.com/..."
                                                    prefix={<TwitterOutlined style={{ marginRight: '10px' }} />}
                                                    
                                                />
                                            </Form.Item>
										</div>
									</Col>
								</Row>
								<Row className="mt-4">
									<Col lg="6" md="6" sm="12">
										<div className="">
											<span>Github</span>
                                            <Form.Item
                                                name="githubUrl"
                                                onChange={(e) => dispatch(setGithubUrl(e.target.value))}
                                                value={githubUrl}
                                            >
                                                <Input
                                                    className="mt-2"
                                                    placeholder="Ex: https://github.com/..."
                                                    prefix={<GithubOutlined style={{ marginRight: '10px' }} />}
                                                    
                                                />
                                            </Form.Item>
										</div>
									</Col>
									<Col lg="6" md="6" sm="12">
										<div className="mobile-top-margin-form">
											<span>Telegram</span>
                                            <Form.Item
                                                name="telegramUrl"
                                                onChange={(e) => dispatch(setTelegramUrl(e.target.value))}
                                                value={telegramUrl}
                                            >
                                                <Input
                                                    className="mt-2"
                                                    placeholder="Ex: https://t.me/..."
                                                    prefix={<GithubOutlined style={{ marginRight: '10px' }} />}
                                                    
                                                />
                                            </Form.Item>
										</div>
									</Col>
								</Row>
								<Row className="mt-4">
									<Col lg="6" md="6" sm="12">
										<div className="">
											<span>Instagram</span>
                                            <Form.Item
                                                name="instagramUrl"
                                                onChange={(e) => dispatch(setInstagramUrl(e.target.value))}
                                                value={instagramUrl}
                                            >
                                                <Input
                                                    className="mt-2"
                                                    placeholder="Ex: https://instagram.com/..."
                                                    prefix={<InstagramOutlined style={{ marginRight: '10px' }} />}
                                                    
                                                />
                                            </Form.Item>
										</div>
									</Col>
									<Col lg="6" md="6" sm="12">
										<div className="mobile-top-margin-form">
											<span>Discord</span>
                                            <Form.Item
                                                name="discordUrl"
                                                onChange={(e) => dispatch(setDiscordUrl(e.target.value))}
                                                value={discordUrl}
                                            >
                                                <Input
                                                    className="mt-2"
                                                    placeholder="Ex: https://t.me/..."
                                                    prefix={<InstagramOutlined style={{ marginRight: '10px' }} />}
                                                    
                                                />
                                            </Form.Item>
										</div>
									</Col>
								</Row>
								<Row className="mt-4">
									<Col lg="12" md="12" sm="12">
										<div className="">
											<span>Reddit</span>
                                            <Form.Item
                                                name="redditUrl"
                                                onChange={(e) => dispatch(setRedditUrl(e.target.value))}
                                                value={redditUrl}
                                            >
                                                <Input
                                                    className="mt-2"
                                                    placeholder="Ex: https://..."
                                                    prefix={<RedditOutlined style={{ marginRight: '10px' }} />}
                                                    
                                                />
                                            </Form.Item>
										</div>
									</Col>
								</Row>
								<Row className="mt-4">
									<Col lg="12" md="12" sm="12">
										<div className="">
											<span>Description</span>
                                            <Form.Item
                                                name="description"
                                                onChange={(e) => dispatch(setDescription(e.target.value))}
                                                value={description}
                                            >
                                                <TextArea
                                                    rows={4}
                                                    placeholder="Ex: This is the best project..."
                                                    // maxLength={6}
                                                />
                                            </Form.Item>
										</div>
									</Col>
								</Row>
							</div>
							<div className="d-flex justify-content-center mt-5">
								{/* <Button className="mx-1" onClick={e => setShowTokenDetailsForm(false)}>Back</Button> */}
								<Button disabled={!canProceedToCreate && !everyFieldFilled} loading={isCreateLoading} className="mx-1" onClick={handleCreateAirdrop}>
									Create New Airdrop
								</Button>
							</div>
						</div>
					</Card>
					</Form>
				</div>
			</div>
		</div>
	);
}

export default TokenDetailsForm;
