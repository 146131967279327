import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './Home/Home'
import AirdropCreate from './Airdrop/CreateAirdrop'
import AirdropList from './Airdrop/AirdropList'
import AirdropDetails from './Airdrop/AirdropDetails'
import NewAirdrops from './NewAirdrops/NewAirdrops'


function Pages() {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/airdrop/create-pool" exact element={<AirdropCreate />} />
      <Route path="/airdrops" exact element={<AirdropList />} />
      <Route path="/create-airdrop" exact element={<NewAirdrops />} />
      <Route path="/airdrop-details/:airdropAddress" exact element={<AirdropDetails />} />
    </Routes>
  )
}

export default Pages