import React, { useEffect, useState } from 'react'
import { Button, Card, Row, Col, notification, Spin, Table } from 'antd';
import ApproveDetailCard from './ApproveDetailCard'
import { useSelector, useDispatch } from 'react-redux'
import { tokenApproval, airDropTokens, getUserBNBBalance, getMaxWalletCount } from '../../Blockchain/web3.service'
import { useWeb3React } from '@web3-react/core'
// import { useNavigate } from 'react-router-dom'
import { setStepNumber } from '../../Redux/HomeSteps'
// import { setTimesForAirdrop } from '../../Redux/CsvDataList'

function ApproveStep() {

    
    const { contractList, totalTokens, selectedToken, selectedTokenBalance, selectedTokenSymbol } = useSelector((state) => state.csvDataList)
    const { stepNumber } = useSelector((state) => state.homeSteps)

    const { library, account } = useWeb3React()
    const dispatch = useDispatch()
    // const { bnbPrice } = getBNBPrice()

    const [isTokenApprovalLoading, setIsTokenApprovalLoading] = useState(false)
    const [tokenApproved, setTokenApproved] = useState(false)
    const [isAirDropLoading, setIsAirDropLoading] = useState(false)
    const [isBnbBalanceLoading, setIsBnbBalanceLoading] = useState(false)
    const [userBnbBalance, setUserBnbBalance] = useState(0)
    const [isMaxWalletCountLoading, setIsMaxWalletCountLoading] = useState(false)
     // eslint-disable-next-line
    const [maxWalletCountForTransaction, setMaxWalletCountForTransaction] = useState(0)
    const [hasToApproveTime, setHasToApproveTime] = useState(0)

    // const navigate = useNavigate()

    const handleBack = () => {
        dispatch(setStepNumber(0))
        // dispatch(setStepNumber(stepNumber - 1))
    }

    const handleApprove = async() => {
        try {
          setIsTokenApprovalLoading(true)
          const tokenAddress = selectedToken
          const spender = process.env.REACT_APP_MULTISENDER_CONTRACT_ADDRESS

        //   const chunkSize = maxWalletCountForTransaction;
        // const chunkSize = 2
        // let metamaskOpened = 0

        // for (let i = 0; i < contractList.length; i += chunkSize) {
        //     const chunk = contractList.slice(i, i + chunkSize);
            
        //     let totalTokensToApprove = 0
        //     chunk.forEach(element => {
        //         totalTokensToApprove = totalTokensToApprove + parseFloat(element.value)
        //     });

        //     const result = await tokenApproval(spender, tokenAddress, totalTokens, library.getSigner())
        //     if (result) {
        //       metamaskOpened = metamaskOpened + 1
        //     //   setMetamaskOpenTime(metamaskOpenTime - 1)
        //       notification['success']({
        //         message: `Set ${metamaskOpened} approve Complete`,
        //         description:
        //           `You have successfully approved set ${metamaskOpened} `,
        //       });
        //       console.log("APPROVAL SUCCESS ", result)
        //     }
        //   }
        const result = await tokenApproval(spender, tokenAddress, totalTokens, library.getSigner())
        if (result) {
            setTokenApproved(true)
            setIsTokenApprovalLoading(false)
            notification['success']({
                message: 'Success',
                description:
                    'Tokens has been approved',
            })
            console.log("APPROVAL SUCCESS ", result)
        }
  
          
        } catch (error) {
          setIsTokenApprovalLoading(false)
          setTokenApproved(false)
            notification['error']({
                message: 'Transaction Execution Failed',
                description: error,
            })
            console.error("ERROR while trying to approve ", error)
        }
      }

    useEffect(() => {
        if (contractList && maxWalletCountForTransaction) {
            let numberOfApprovals = 0
            const result = Math.floor(contractList.length / maxWalletCountForTransaction);
            if (contractList.length % maxWalletCountForTransaction !== 0) {
                numberOfApprovals = result + 1
            } else {
                numberOfApprovals = result
            }
            // console.log('numberOfApprovals', numberOfApprovals);
            setHasToApproveTime(numberOfApprovals)
        }
    }, [contractList, maxWalletCountForTransaction])

    const handleAirDrop = async() => {
        try {
          setIsAirDropLoading(true)
          const tokenAddress = selectedToken

            const chunkSize = maxWalletCountForTransaction;
        //   const chunkSize = 2
        let metamaskOpened = 0

        for (let i = 0; i < contractList.length; i += chunkSize) {
            const contractListChunked = contractList.slice(i, i + chunkSize);

            const result = await airDropTokens(contractListChunked, tokenAddress, library.getSigner())
            if (result) {
              metamaskOpened = metamaskOpened + 1
            //   setMetamaskOpenTime(metamaskOpenTime - 1)
              notification['success']({
                message: `Set ${metamaskOpened} airdroped Complete`,
                description:
                  `You have successfully airdroped set ${metamaskOpened} `,
              });
              console.log("airDropTokens SUCCESS ", result)
              dispatch(setStepNumber(2))
            }
          }
          setIsAirDropLoading(false)
          
        } catch (error) {
          setIsAirDropLoading(false)
          console.log(error);
        }
      }

      const fetchUserBnbBalance = async () => {
        setIsBnbBalanceLoading(true)
        try {
            const bnbBalanceResponse = await getUserBNBBalance(account)
            setUserBnbBalance(bnbBalanceResponse)
            setIsBnbBalanceLoading(false)
        } catch (error) {
            setUserBnbBalance(0)
            setIsBnbBalanceLoading(false)
            console.log(error);
            notification['error']({
                message: 'Error',
                description:
                    'Error while fetching BNB balance',
            })
        }
      }

      const fetchMaxWalletCount = async () => {
        setIsMaxWalletCountLoading(true)
        try {
            const walletCountResponse = await getMaxWalletCount(account)
            setMaxWalletCountForTransaction(parseFloat(walletCountResponse))
            setIsMaxWalletCountLoading(false)
        } catch (error) {
            setMaxWalletCountForTransaction(0)
            setIsMaxWalletCountLoading(false)
            console.log(error);
            notification['error']({
                message: 'Error',
                description:
                    'Error while fetching max wallet count',
            })
        }
      }

      useEffect(() => {
        fetchUserBnbBalance()
        fetchMaxWalletCount()
        // eslint-disable-next-line
      }, [account])

      const columns = [
        {
          title: 'Wallet Address',
          dataIndex: 'contractAddress',
          render: (text) => <a href={`https://testnet.bscscan.com/address/${text}`} target={'_blank'} rel="noreferrer">{text}</a>,
            ellipsis: true,
            width: '70%',
        },
        {
          title: 'Amount',
          dataIndex: 'value',
          align: 'right',
        },
      ];

    return (
        <div>
            <div className='col-lg-12 mt-4'>
                <div className='card-border-wrap'>
                    <Card className='rfc-button-card'>
                        <Row className="mt-4">
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                {
                                    isMaxWalletCountLoading ? (
                                        <div className='d-flex justify-content-center'>
                                             <Spin size="small" />
                                        </div>
                                    ) : (
                                        <ApproveDetailCard 
                                            topic='times airdrop will run'
                                            value={hasToApproveTime}
                                            symbol=''
                                        />
                                    )
                                }
                                
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className='mobile-top-margin'>
                                <ApproveDetailCard 
                                    topic='Total number of tokens to send'
                                    value={totalTokens}
                                    symbol={selectedTokenSymbol}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <ApproveDetailCard 
                                    topic={`Your ${selectedTokenSymbol} balance`}
                                    value={selectedTokenBalance}
                                    symbol={selectedTokenSymbol}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className='mobile-top-margin'>
                                <ApproveDetailCard 
                                    topic='Your BNB balance'
                                    value={userBnbBalance}
                                    symbol='BNB'
                                    loading={isBnbBalanceLoading}
                                />
                            </Col>
                        </Row>
                        <div className='mt-4 mx-2'>
                            <h5>List of recipients</h5>
                        </div>
                        <Table 
                            className="mt-4" 
                            dataSource={contractList} 
                            columns={columns}
                            showHeader={false}
                        />
                        <Row className='mt-4'>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <div className='mx-2'>
                                    <Button block onClick={handleBack} disabled={tokenApproved}>
                                        Back to edit
                                    </Button>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className='mobile-top-margin'>
                                <div className='mx-2'>
                                    {
                                        !tokenApproved ? (
                                            <Button block onClick={handleApprove} loading={isTokenApprovalLoading} disabled={isMaxWalletCountLoading}>
                                                Approve
                                            </Button>
                                        ) : (
                                            <Button type="primary" block 
                                                onClick={handleAirDrop} 
                                                disabled={!tokenApproved}
                                                loading={isAirDropLoading}
                                            >
                                                airDrop
                                            </Button>
                                        )
                                    }
                                    
                                </div>
                            </Col>
                        </Row>
                    </Card> 
                </div>
            </div>
        </div>
    )
}

export default ApproveStep;