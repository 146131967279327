import React, { useEffect, useState } from 'react'
import { Steps } from 'antd';
import './Airdrop.css'
import { useSelector } from 'react-redux'
// import { FormOutlined, FileDoneOutlined, SendOutlined, ArrowLeftOutlined } from '@ant-design/icons'
// import { setCreateStepNumber } from '../../Redux/CreateAirdropSteps'
import TokenDetailsForm from '../../Components/Airdrop/Create/TokenDetailsForm'
import TokenValidate from '../../Components/Airdrop/Create/TokenValidate'

function Home() {

  const [current, setCurrent] = useState(0);
  const descriptionPrepare = 'Your Token Address';
  const descriptionApprove = 'Your Token Details';

  const steps = [
    {
      title: 'Validate Token',
      content: <TokenValidate />,
      description: descriptionPrepare,
      // icon: <FormOutlined />
    },
    {
      title: 'Details of Token',
      content: <TokenDetailsForm />,
      description: descriptionApprove,
      // icon: <SendOutlined />
    }
  ];

  // const dispatch = useDispatch()

  const { createStepNumber } = useSelector((state) => state.createAirdropSteps)

  useEffect(() => {
    if (createStepNumber) {
      setCurrent(createStepNumber);
    }
  }, [createStepNumber])

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    description: item.description,
    icon: item.icon
  }));
  // const prev = () => {
  //   setCurrent(current - 1);
  //   dispatch(setCreateStepNumber(current - 1))
  // };

  const onChange = (value) => {
    setCurrent(value);
  };

  return (
    <div className='text-light container mt-5'>

      <div className='d-flex justify-content-center align-items-center'>
        <div className='col-lg-8 col-md-8 col-sm-12'>
          <Steps current={current} items={items} onChange={onChange} responsive={false} />
          <div className="steps-content">{steps[current].content}</div>

        </div>
      </div>



    </div>
  )
}

export default Home